import { gql } from '@apollo/client'

export const WORK_PREP_BY_ID = gql`
	query WorkPrepById($workPrepId: String!, $jwt: String!) {
		workPrepById(workPrepId: $workPrepId, jwt: $jwt)
	}
`

export const WORK_PREP_REDIRECT_OBJECT_BY_UUID = gql`
	query WorkPrepRedirectObjectByUUID($uuid: String!) {
		workPrepRedirectObjectByUUID(uuid: $uuid)
	}
`

export const WORK_PREPS_BY_MALL = gql`
	query WorkPrepsByMall($mall: String!, $jwt: String!) {
		workPrepsByMall(mall: $mall, jwt: $jwt)
	}
`

export const WORK_PREPS_BY_ACCOUNT = gql`
	query WorkPrepsByAccount($jwt: String!) {
		workPrepsByAccount(jwt: $jwt)
	}
`

export const GET_BASE64_FROM_FILES = gql`
	query GetBase64FromFiles($workPrepId: String!) {
		getBase64FromFiles(workPrepId: $workPrepId)
	}
`

export const FIRE_ALARMS_BY_MALL = gql`
	query FireAlarmsByMall($mall: String!, $jwt: String!) {
		fireAlarmsByMall(mall: $mall, jwt: $jwt)
	}
`

export const SERVICE_WORKS_BY_MALL = gql`
	query ServiceWorksByMall($mall: String!, $jwt: String!) {
		serviceWorksByMall(mall: $mall, jwt: $jwt)
	}
`

export const SERVICE_WORKS_BY_ACCOUNT = gql`
	query ServiceWorksByAccount($jwt: String!) {
		serviceWorksByAccount(jwt: $jwt)
	}
`

export const FIRE_ALARMS_BY_ACCOUNT = gql`
	query FireAlarmsByAccount($jwt: String!) {
		fireAlarmsByAccount(jwt: $jwt)
	}
`

export const FIRE_ALARM_BY_ID = gql`
	query FireAlarmById($fireAlarmId: String!, $jwt: String!) {
		fireAlarmById(fireAlarmId: $fireAlarmId, jwt: $jwt)
	}
`

export const SERVICE_WORK_BY_ID = gql`
	query ServiceWorkById($serviceWorkId: String!, $jwt: String!) {
		serviceWorkById(serviceWorkId: $serviceWorkId, jwt: $jwt)
	}
`

export const GET_SIGN_URL = gql`
	query getSignUrl($caseId: String!) {
		getSignUrl(caseId: $caseId)
	}
`

export const CHECK_VALID_ID = gql`
	query checkValidId($workPrepId: String!) {
		checkValidId(workPrepId: $workPrepId)
	}
`

export const GET_WORK_HISTORY_BY_COMPANY = gql`
	query GetWorkHistoryByCompany($company: String!, $jwt: String!) {
		workHistoryByCompany(company: $company, jwt: $jwt)
	}
`

export const GET_ENTIRE_WORK_HISTORY = gql`
	query getEntireWorkHistory($jwt: String!) {
		getEntireWorkHistory(jwt: $jwt)
	}
`

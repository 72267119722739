import {
	FireAlarmAnswers,
	WorkPrepAnswers,
	WorkPrepAnswers_NL,
} from '../../types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { IconButton, Box, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'
import { comments } from '../../types'
import {
	REMOVE_COMMENT,
	REMOVE_COMMENT_FIRE_ALARM,
} from '../../graphql/mutations/workPreps'
import {
	FIRE_ALARM_BY_ID,
	WORK_PREP_BY_ID,
} from '../../graphql/queries/workPreps'
import { apolloClient } from '../../index'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useAuth } from '../../hooks/useAuth'

interface Props {
	data: WorkPrepAnswers | WorkPrepAnswers_NL | FireAlarmAnswers
	isFireAlarm?: boolean
	className?: string
}

const CommentSection = ({ data, isFireAlarm, className = '' }: Props) => {
	const { t } = useTranslation()
	const { userEmail, jwt } = useAuth()
	let listKey = 0

	if (!data.comments) {
		return null
	}

	const comments: comments[] = Object.values(data.comments)

	const sortedComments: comments[] = comments.sort((comment1, comment2) => {
		const dateComment1 = new Date(comment1.commentAddedOn)
		const dateComment2 = new Date(comment2.commentAddedOn)

		if (dateComment1 > dateComment2) {
			return 1
		} else if (dateComment1 < dateComment2) {
			return -1
		}
		return 0
	})

	const removeComment = async (commentId: string) => {
		if (isFireAlarm) {
			await apolloClient.mutate({
				mutation: REMOVE_COMMENT_FIRE_ALARM,
				variables: {
					fireAlarmId: data.id,
					commentId: commentId,
				},
				refetchQueries: [
					{
						query: FIRE_ALARM_BY_ID,
						variables: {
							fireAlarmId: data.id,
							jwt,
						},
					},
				],
			})
		} else {
			await apolloClient.mutate({
				mutation: REMOVE_COMMENT,
				variables: {
					workPrepId: data.id,
					commentId: commentId,
				},
				refetchQueries: [
					{
						query: WORK_PREP_BY_ID,
						variables: {
							workPrepId: data.id,
							jwt,
						},
					},
				],
			})
		}
	}

	const commentList = sortedComments.map((comment) => {
		listKey += 1

		return (
			<ListItem
				alignItems={'flex-start'}
				className="flex flex-col"
				key={listKey}
			>
				<Box
					sx={{
						width: '100%',
						maxWidth: '400px',
						whiteSpace: 'normal',
						overflowWrap: 'break-word',
						textAlign: 'left',
						padding: 1,
						borderRadius: 1,
						boxShadow: 3,
					}}
				>
					<Box
						sx={{
							minHeight: 30,
							display: 'flex',
							alignItems: 'center',
							overflowWrap: 'break-word',
						}}
					>
						<AccountCircleIcon />
						<Typography
							sx={{
								width: 350,
								paddingLeft: 1,
								fontSize: 14,
								fontWeight: 'bold',
							}}
						>
							{comment.commentCreator
								? comment.commentCreator +
								  ', ' +
								  comment.commentAddedBy
								: comment.commentAddedBy}
						</Typography>
					</Box>

					<Typography
						sx={{
							fontSize: 12,
							fontWeight: 'bold',
							textDecoration: 'underline',
						}}
					>
						{comment.commentHeading}
					</Typography>
					<Typography
						sx={{
							whiteSpace: 'pre-line',
							fontSize: 12,
						}}
					>
						{comment.commentMessage}
					</Typography>
					<Box display="flex" justifyContent="flex-end">
						<Typography
							sx={{
								paddingTop: 1.5,
								fontSize: 12,
							}}
						>
							{new Date(comment.commentAddedOn).toLocaleString(
								t('common.lang'),
								{
									year: 'numeric',
									month: 'numeric',
									day: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
								}
							)}
						</Typography>
						{userEmail == comment.commentAddedBy && (
							<IconButton
								size="small"
								aria-label="delete"
								onClick={() => removeComment(comment.commentId)}
							>
								<DeleteIcon />
							</IconButton>
						)}
					</Box>
				</Box>
			</ListItem>
		)
	})

	return (
		<List className="flex flex-col" dense={true}>
			{commentList}
		</List>
	)
}

export default CommentSection

import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { WorkPrepAnswers, WorkPrepAnswers_NL } from '../../../types'
import { PrettyDateWithTime } from '../../common/PrettyDate'

interface Props {
	currentWorkPrep: WorkPrepAnswers_NL | WorkPrepAnswers
	className?: string
}

const styles = StyleSheet.create({
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100%',
		paddingRight: '27px',
		paddingLeft: '43px',
		marginLeft: '-43px',
		marginTop: '10px',
	},
	fieldContainer: {
		display: 'flex',
		flex: '1 1 200px',
		flexDirection: 'column',
		marginRight: '15px',
	},
	fieldHeader: {
		fontFamily: 'RobotoBold',
		fontSize: 10,
	},
	fieldValue: {
		marginTop: '5px',
		fontSize: 10,
		lineHeight: 1.65,
	},
})

const SignStatusListPDF_NL = ({ currentWorkPrep }: Props) => {
	const { t } = useTranslation()
	return (
		<View>
			<View style={styles.row}>
				<Text style={styles.fieldHeader}>
					{t('workPrep.signStatus')}
				</Text>
			</View>
			<View style={styles.row}>
				<View style={{ ...styles.fieldContainer, flex: 2 }}>
					<Text style={styles.fieldHeader}>
						{t('workPrep.createdBy')}
					</Text>
					<Text style={styles.fieldValue}>
						{currentWorkPrep.creatorOfWorkPrep.name || '-'}
					</Text>
					<Text style={styles.fieldHeader}>
						{t('workPrep.status')}
					</Text>
					<Text style={styles.fieldValue}>
						{currentWorkPrep.creatorOfWorkPrep.signStatus || '-'}
					</Text>
					<Text style={styles.fieldHeader}>{t('workPrep.date')}</Text>
					<Text style={styles.fieldValue}>
						{currentWorkPrep.creatorOfWorkPrep
							.signStatusChangedOn ? (
							<PrettyDateWithTime
								dateString={
									currentWorkPrep.creatorOfWorkPrep
										.signStatusChangedOn
								}
							/>
						) : (
							'-'
						)}
					</Text>
					<Text style={styles.fieldHeader}>
						{t('workPrep.signatureType')}
					</Text>
					<Text style={styles.fieldValue}>
						{currentWorkPrep.creatorOfWorkPrep.signatureType || '-'}
					</Text>
				</View>
				{currentWorkPrep.projectManager?.exists ? (
					<View style={{ ...styles.fieldContainer, flex: 2 }}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.projectManager')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.projectManager.name || '-'}
						</Text>
						<Text style={styles.fieldHeader}>
							{t('workPrep.status')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.projectManager.signStatus || '-'}
						</Text>
						<Text style={styles.fieldHeader}>
							{t('workPrep.date')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.projectManager
								.signStatusChangedOn ? (
								<PrettyDateWithTime
									dateString={
										currentWorkPrep.projectManager
											.signStatusChangedOn
									}
								/>
							) : (
								'-'
							)}
						</Text>
						<Text style={styles.fieldHeader}>
							{t('workPrep.signatureType')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.projectManager.signatureType ||
								'-'}
						</Text>
					</View>
				) : null}

				<View style={{ ...styles.fieldContainer, flex: 2 }}>
					<Text style={styles.fieldHeader}>
						{t('workPrep.management')}
					</Text>
					<Text style={styles.fieldValue}>
						{currentWorkPrep.statusChangedBy || '-'}
					</Text>
					<Text style={styles.fieldHeader}>
						{t('workPrep.status')}
					</Text>
					<Text style={styles.fieldValue}>
						{currentWorkPrep.status || '-'}
					</Text>
					<Text style={styles.fieldHeader}>{t('workPrep.date')}</Text>
					<Text style={styles.fieldValue}>
						{currentWorkPrep.statusChangedOn ? (
							<PrettyDateWithTime
								dateString={currentWorkPrep.statusChangedOn}
							/>
						) : (
							'-'
						)}
					</Text>
					<Text style={styles.fieldHeader}>
						{t('workPrep.signatureType')}
					</Text>
					<Text style={styles.fieldValue}>
						{currentWorkPrep.status ? 'Admin panel' : '-'}
					</Text>
				</View>
			</View>
		</View>
	)
}

export default SignStatusListPDF_NL

import { useLazyQuery } from '@apollo/client'
import { Grid, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { GET_SIGN_URL } from '../../graphql/queries/workPreps'
import { urw_country } from '../../constants'

interface props {
	signStatus: string
	setSnackbarData: any
}

const FireAlarmSignStatus = ({ signStatus, setSnackbarData }: props) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [getSignUrl] = useLazyQuery(GET_SIGN_URL)

	const navigateHome = () => {
		navigate('/')
	}

	const navigateSign = async () => {
		setSnackbarData({
			message: t('common.waitPlease'),
			severity: 'info',
			open: true,
		})
		const res = await getSignUrl({
			variables: {
				caseId: signStatus,
			},
		})
		try {
			const redirectUrl = new URL(res.data.getSignUrl)
			window.location.replace(redirectUrl)
		} catch {
			setSnackbarData({
				message: t('workPrep.urlError'),
				severity: 'error',
				open: true,
			})
		}
	}

	const headerText =
		signStatus === 'completed'
			? t('fireAlarmShutdown.form.submitted')
			: t('fireAlarmShutdown.form.notSubmitted')
	const infoText =
		signStatus === 'completed'
			? urw_country === 'NL'
				? t('fireAlarmShutdown.form.submittedInfoText_NL')
				: t('fireAlarmShutdown.form.submittedInfoText')
			: t('fireAlarmShutdown.form.notSubmittedInfoText')

	return (
		<Grid
			container
			columnSpacing={4}
			rowSpacing={2}
			direction="column"
			alignItems="center"
			justifyContent="center"
			mt={'100px'}
			mb={'100px'}
		>
			<Grid item>
				<Typography fontWeight={500} fontSize={25}>
					{headerText}
				</Typography>
			</Grid>
			<Grid item>
				<Typography
					style={{ whiteSpace: 'pre-line' }}
					fontSize={14}
					textAlign="center"
					maxWidth={'500px'}
					width={'100%'}
				>
					{infoText}
				</Typography>
			</Grid>
			<Grid item>
				<Grid
					container
					columnSpacing={2}
					rowSpacing={2}
					display={'flex'}
					justifyContent={'center'}
				>
					{signStatus != 'completed' && (
						<Grid item>
							<Button onClick={navigateSign} variant="contained">
								{t('fireAlarmShutdown.form.tryAgain')}
							</Button>
						</Grid>
					)}
					<Grid item>
						<Button onClick={navigateHome} variant="outlined">
							{t('fireAlarmShutdown.form.toStart')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default FireAlarmSignStatus

import { Checkbox, FormControlLabel, SxProps, Typography } from '@mui/material'
import { useField, useFormikContext } from 'formik'

interface Props {
	label: any
	name: string
	sx?: SxProps
	customHandleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CustomCheckboxInput = ({
	label,
	name,
	sx,
	customHandleChange,
}: Props) => {
	const [field, meta, helpers] = useField({ name })
	const formik = useFormikContext()

	return (
		<FormControlLabel
			sx={sx}
			control={
				<Checkbox
					{...field}
					checked={Boolean(field.value)}
					value={Boolean(field.value)}
					sx={{
						color:
							meta.touched && Boolean(meta.error)
								? '#d32f2f'
								: 'primary.main',
					}}
					onChange={
						customHandleChange
							? customHandleChange
							: formik.handleChange
					}
				/>
			}
			label={<Typography fontWeight={300}>{label}</Typography>}
		/>
	)
}

export default CustomCheckboxInput

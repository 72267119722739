import { useQuery, useMutation } from '@apollo/client'
import {
	Alert,
	AlertProps,
	Button,
	Grid,
	Snackbar,
	SnackbarProps,
	Tooltip,
	Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import SettingsIcon from '@mui/icons-material/Settings'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import AddCommentIcon from '@mui/icons-material/AddComment'
import Divider from '@mui/material/Divider'
import { useNavigate, useParams } from 'react-router-dom'
import FieldDisplay from '../../components/admin/FieldDisplay'
import SignStatusList from '../../components/admin/SignStatusList'
import CommentSection from '../../components/admin/CommentSection'
import PaperLooksContainer from '../../components/common/PaperLooksContainer'
import { WORK_PREP_BY_ID } from '../../graphql/queries/workPreps'
import {
	DELETE_WORK_PREP,
	UPDATE_WORK_PREP_STATUS,
	REQUEST_NEW_SIGNATURES,
	SEND_REMINDER,
	ADD_COMMENT,
	SIGN_PROJECT_MANAGER,
} from '../../graphql/mutations/workPreps'
import { WorkPrepAnswers, commentForm } from '../../types'
import Layout from '../../components/common/Layout'
import { useState } from 'react'
import { apolloClient } from '../..'
import DocumentPDF from '../../components/admin/pdf/DocumentPDF'
import { pdf } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { Add, Repeat } from '@mui/icons-material'
import RequestCompletionModal from '../../components/admin/RequestCompletionModal'
import CommentModal from '../../components/admin/CommentModal'
import { isAuthorized } from '../../utils/helperFunctions'
import AdminMenu from '../../components/admin/AdminMenu'
import Box from '@mui/material/Box'
import WorkPrepStatusDisplay from '../../components/common/WorkPrepStatusDisplay'
import FileUrlSingle from '../../components/admin/FileUrlSingle'
import ChangeStatusModal from '../../components/admin/ChangeStatusModal'
import NotLoggedIn from '../../components/common/NotLoggedIn'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import Updates from '../../components/admin/Updates'
import DeleteWorkModal from '../../components/admin/DeleteWorkModal'
import { useAuth } from '../../hooks/useAuth'
import { PrettyDate } from '../../components/common/PrettyDate'
import RestoreIcon from '@mui/icons-material/Restore'

const WorkPrepDetails = () => {
	const [snackbarOptions, setSnackbarOptions] = useState<
		SnackbarProps & AlertProps
	>({
		open: false,
		severity: 'success',
		autoHideDuration: null,
		anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
		message: '',
	})
	const [requestCompletionModalIsOpen, setRequestCompletionModalIsOpen] =
		useState<boolean>(false)
	const [commentModalIsOpen, setCommentModalIsOpen] = useState<boolean>(false)
	const [approveModalIsOpen, setApproveModalIsOpen] = useState<boolean>(false)
	const [rejectModalIsOpen, setRejectModalIsOpen] = useState<boolean>(false)
	const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false)
	const { t, i18n } = useTranslation()
	const lang = i18n.resolvedLanguage as 'sv' | 'en'

	const navigate = useNavigate()

	const params = useParams()

	const { userEmail, role, jwt } = useAuth()

	const hideSnackBar = () => {
		setSnackbarOptions({
			...snackbarOptions,
			open: false,
		})
	}

	const [deleteWorkPrep] = useMutation(DELETE_WORK_PREP, {
		errorPolicy: 'all',
	})

	const { loading, data } = useQuery(WORK_PREP_BY_ID, {
		variables: { workPrepId: params.workPrepId, jwt },
		fetchPolicy: 'network-only',
	})

	const newFireAlarm = () => {
		navigate('/fire-alarm', {
			state: {
				id: currentWorkPrep.id,
				user: userEmail,
				relevantMall: currentWorkPrep.relevantMall,
			},
		})
	}

	const submitComment = async (values: any) => {
		const comment: commentForm = {
			commentHeading: values.commentHeading,
			commentMessage: values.commentMessage,
			commentAddedBy: userEmail ? userEmail : '-',
			commentAddedOn: new Date().toString(),
			commentCreator: values.commentCreator,
			id: currentWorkPrep.id,
		}
		const commentJsonString = JSON.stringify(comment)

		setSnackbarOptions({
			message: t('workPrepDetails.commentModal.addingComment'),
			severity: 'info',
			open: true,
		})

		await apolloClient.mutate({
			mutation: ADD_COMMENT,
			variables: {
				comment: commentJsonString,
			},
			refetchQueries: [
				{
					query: WORK_PREP_BY_ID,
					variables: {
						workPrepId: currentWorkPrep.id,
						jwt,
					},
				},
			],
		})

		setSnackbarOptions({
			message: t('workPrepDetails.commentModal.requestSent'),
			severity: 'success',
			open: true,
		})
	}

	const sendSignatureReminder = async () => {
		setSnackbarOptions({
			...snackbarOptions,
			open: true,
			severity: 'info',
			message: t('workPrepDetails.updating'),
		})

		const res = await apolloClient.mutate({
			mutation: SEND_REMINDER,
			variables: {
				workPrepId: currentWorkPrep.id,
				jwt,
			},
		})

		if (res.data.sendReminder === 'Assently error') {
			setSnackbarOptions({
				...snackbarOptions,
				open: true,
				severity: 'error',
				message: t('workPrepDetails.reminderSentError'),
				autoHideDuration: 5000,
			})
		} else if (res.data.sendReminder === 'No reminder') {
			setSnackbarOptions({
				...snackbarOptions,
				open: true,
				severity: 'error',
				message: t('workPrepDetails.noReminder'),
				autoHideDuration: 5000,
			})
		} else {
			setSnackbarOptions({
				...snackbarOptions,
				open: true,
				severity: 'success',
				message: t('workPrepDetails.reminderSent'),
				autoHideDuration: 5000,
			})
		}
	}

	const requestNewSignatures = async () => {
		setSnackbarOptions({
			...snackbarOptions,
			open: true,
			severity: 'info',
			message: t('workPrepDetails.updating'),
		})

		const tempCurrentWorkPrep = { ...currentWorkPrep }
		tempCurrentWorkPrep.creatorOfWorkPrep.signStatus = '' //Removes the sign list on PDF
		const workPrepBlob = await pdf(
			<DocumentPDF
				currentWorkPrep={tempCurrentWorkPrep}
				customLang={lang}
			/>
		).toBlob()

		const workPrepPDF = new File([workPrepBlob], 'filename.pdf')

		const res = await apolloClient.mutate({
			mutation: REQUEST_NEW_SIGNATURES,
			variables: {
				workPrepId: currentWorkPrep.id,
				isBasUUpdated: true,
				isBasPUpdated: true,
				isConstructionEnvironmentWorkPlanUpdated: true,
				workPrepPDF,
				jwt,
			},
			refetchQueries: [
				{
					query: WORK_PREP_BY_ID,
					variables: { workPrepId: currentWorkPrep.id, jwt },
				},
			],
		})

		if (res.data.requestNewSignatures === 'Assently error') {
			setSnackbarOptions({
				...snackbarOptions,
				open: true,
				severity: 'error',
				message: t('workPrep.assentlyError'),
			})
		} else {
			setSnackbarOptions({
				...snackbarOptions,
				open: true,
				severity: 'success',
				message: t('workPrepDetails.newSignatureRequested'),
				autoHideDuration: 5000,
			})
		}
	}

	const signWorkPrepProjectManager = async () => {
		setSnackbarOptions({
			...snackbarOptions,
			open: true,
			severity: 'info',
			message: t('workPrepDetails.updating'),
		})
		await apolloClient.mutate({
			mutation: SIGN_PROJECT_MANAGER,
			variables: {
				workPrepId: currentWorkPrep.id,
				jwt,
			},
			refetchQueries: [
				{
					query: WORK_PREP_BY_ID,
					variables: {
						workPrepId: currentWorkPrep.id,
						userEmail: userEmail,
						jwt,
					},
				},
			],
		})
		setSnackbarOptions({
			...snackbarOptions,
			open: true,
			severity: 'success',
			message: t('workPrepDetails.updated'),
			autoHideDuration: 5000,
		})
	}

	const updateWorkPrepStatus = async (status: string, message: string) => {
		const date = new Date().toString()
		setSnackbarOptions({
			...snackbarOptions,
			open: true,
			severity: 'info',
			message: t('workPrepDetails.updating'),
		})

		const tempCurrentWorkPrep = { ...currentWorkPrep }

		tempCurrentWorkPrep.status = status
		tempCurrentWorkPrep.statusChangedOn = date

		const workPrepBlob = await pdf(
			<DocumentPDF
				currentWorkPrep={tempCurrentWorkPrep}
				customLang={lang}
			/>
		).toBlob()

		const workPrepPDF = new File([workPrepBlob], 'filename.pdf')

		await apolloClient.mutate({
			mutation: UPDATE_WORK_PREP_STATUS,
			variables: {
				workPrepId: currentWorkPrep.id,
				userEmail,
				newStatus: status,
				newStatusChangedOn: date,
				message: message,
				workPrepPDF,
				jwt,
			},
			refetchQueries: [
				{
					query: WORK_PREP_BY_ID,
					variables: {
						workPrepId: currentWorkPrep.id,
						userEmail: userEmail,
						jwt,
					},
				},
			],
		})

		setSnackbarOptions({
			...snackbarOptions,
			open: true,
			severity: 'success',
			message: t('workPrepDetails.updated'),
			autoHideDuration: 5000,
		})
	}

	const handleDeleteWorkPrep = async () => {
		deleteWorkPrep({
			variables: {
				jwt,
				workPrepId: currentWorkPrep.id,
			},
		})
		navigate('/admin-panel')
	}

	if (loading && !data)
		return (
			<AdminMenu>
				<LoadingSpinner />
			</AdminMenu>
		)

	if (!role) return <NotLoggedIn />

	const currentWorkPrep: WorkPrepAnswers = JSON.parse(data.workPrepById)

	const rowClasses = 'flex flex-wrap gap-x-7 md:gap-x-10 gap-y-4'

	//TODO: Change/add to look at the role and if role != some type of admin - "You need permission to vied this page" etc.
	//Could maybe done in "App.tsx"?? Might be worth looking into - to prevent duplicate code

	const isOwnWorkPrep =
		userEmail === currentWorkPrep?.isCreatedLoggedIn?.email ||
		userEmail === currentWorkPrep?.projectManager?.email

	if (!isAuthorized(role, currentWorkPrep?.relevantMall) && !isOwnWorkPrep) {
		return <NotLoggedIn />
	}

	const scrollToOverview = () => {
		const element = document.getElementById('WorkPrepOverview')
		element?.scrollIntoView({ behavior: 'smooth' })
	}
	const scrollToCreator = () => {
		const element = document.getElementById('WorkPrepCreator')
		element?.scrollIntoView({ behavior: 'smooth' })
	}
	const scrollToRequirements = () => {
		const element = document.getElementById('WorkPrepRequirements')
		element?.scrollIntoView({ behavior: 'smooth' })
	}
	const scrollToSafety = () => {
		const element = document.getElementById('WorkPrepSafety')
		element?.scrollIntoView({ behavior: 'smooth' })
	}
	const scrollToSignStatus = () => {
		const element = document.getElementById('WorkPrepSignStatus')
		element?.scrollIntoView({ behavior: 'smooth' })
	}
	const scrollToComments = () => {
		const element = document.getElementById('WorkPrepComments')
		element?.scrollIntoView({ behavior: 'smooth' })
	}

	const checkIfSigningIsComplete = () => {
		return !(
			!currentWorkPrep.creatorOfWorkPrep.signStatus ||
			(currentWorkPrep.constructionWorkWillTakePlace.urwBaseU.exists &&
				!currentWorkPrep.constructionWorkWillTakePlace.urwBaseU
					.signStatus) ||
			(currentWorkPrep.constructionWorkWillTakePlace.baseP.exists &&
				!currentWorkPrep.constructionWorkWillTakePlace.baseP
					.signStatus) ||
			(currentWorkPrep.projectManager?.exists &&
				!currentWorkPrep.projectManager.signStatus)
		)
	}

	const isUserProjectManager =
		userEmail == currentWorkPrep.projectManager.email

	const allActions = [
		'pdf',
		'edit',
		'remind',
		'newSignatures',
		'completion',
		'changeStatus',
		'fireAlarm',
		'remove',
		'signProjectManager',
	]

	const roleActions = {
		SuperAdmin: [
			'pdf',
			'edit',
			'remind',
			'newSignatures',
			'completion',
			'changeStatus',
			'remove',
			'signProjectManager',
		],
		'Admin - Alla centrum': [
			'pdf',
			'edit',
			'remind',
			'newSignatures',
			'completion',
			'changeStatus',
			'remove',
			'signProjectManager',
		],
		'Admin - Westfield Mall of Scandinavia': [
			'pdf',
			'edit',
			'remind',
			'newSignatures',
			'completion',
			'changeStatus',
			'remove',
			'signProjectManager',
		],
		'Admin - Nacka Forum': [
			'pdf',
			'edit',
			'remind',
			'newSignatures',
			'completion',
			'changeStatus',
			'remove',
			'signProjectManager',
		],
		'Admin - Täby Centrum': [
			'pdf',
			'edit',
			'remind',
			'newSignatures',
			'completion',
			'changeStatus',
			'remove',
			'signProjectManager',
		],
		'Observer - MOS': ['pdf'],
		'Observer - Nacka Forum': ['pdf'],
		'Observer - Täby Centrum': ['pdf'],
		'Drift - Westfield Mall of Scandinavia': [
			'pdf',
			'edit',
			'remind',
			'newSignatures',
			'completion',
			'changeStatus',
			'remove',
		],
		'Drift - Nacka Forum': [
			'pdf',
			'edit',
			'remind',
			'newSignatures',
			'completion',
			'changeStatus',
			'remove',
		],
		'Drift - Täby Centrum': [
			'pdf',
			'edit',
			'remind',
			'newSignatures',
			'completion',
			'changeStatus',
			'remove',
		],
		Upprättare: ['pdf', 'fireAlarm', 'remove'],
		projectManager: [
			'pdf',
			'edit',
			'remind',
			'newSignatures',
			'completion',
			'signProjectManager',
		],
	}

	return (
		<>
			<AdminMenu>
				<Layout>
					<PaperLooksContainer noLogo backBtnPath="/admin-panel">
						<Box>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									mt: 8,
									flexWrap: 'wrap',
								}}
							>
								<Typography
									variant="h5"
									component="h2"
									sx={{
										fontWeight: 500,
										mt: 1,
										alignItems: 'center',
									}}
								>
									{t('workPrep.title') + ' ID: '}
									{currentWorkPrep.id}
								</Typography>
								<Box className="flex flex-col gap-0 items-end">
									<WorkPrepStatusDisplay
										status={
											checkIfSigningIsComplete()
												? currentWorkPrep.status
												: 'AwaitingSigning'
										}
									/>
									<Button
										variant="contained"
										sx={{ gap: 1 }}
										onClick={() =>
											navigate(
												`../admin-panel/work-history/${currentWorkPrep.creatorOfWorkPrep.company}`
											)
										}
									>
										<RestoreIcon />
										{t('workHistory.creatorHistoryTitle')}
									</Button>
								</Box>
							</Box>
							<RequestCompletionModal
								isOpen={requestCompletionModalIsOpen}
								setIsOpen={setRequestCompletionModalIsOpen}
								setSnackbarData={setSnackbarOptions}
								workPrepId={currentWorkPrep.id}
								type="workPrep"
							/>
							<CommentModal
								isOpen={commentModalIsOpen}
								setIsOpen={setCommentModalIsOpen}
								submitComment={submitComment}
							/>
							<ChangeStatusModal
								isOpen={approveModalIsOpen}
								setIsOpen={setApproveModalIsOpen}
								submitStatus={updateWorkPrepStatus}
								status={'Approved'}
							/>
							<ChangeStatusModal
								isOpen={rejectModalIsOpen}
								setIsOpen={setRejectModalIsOpen}
								submitStatus={updateWorkPrepStatus}
								status={'Rejected'}
							/>
							<DeleteWorkModal
								isOpen={deleteModalIsOpen}
								setIsOpen={setDeleteModalIsOpen}
								submit={handleDeleteWorkPrep}
							/>
							<Box
								sx={{
									display: 'flex',
									borderBottom: 2,
									borderColor: 'divider',
									mt: 5,
									mb: 4,
									flexWrap: 'wrap',
								}}
							>
								<Button onClick={scrollToOverview}>
									{t('workPrepDetails.scrollMenu.overview')}
								</Button>
								<Divider
									orientation="vertical"
									variant="middle"
									flexItem
								/>
								<Button onClick={scrollToCreator}>
									{t('workPrepDetails.scrollMenu.creator')}
								</Button>
								<Divider
									orientation="vertical"
									variant="middle"
									flexItem
								/>
								<Button onClick={scrollToRequirements}>
									{t(
										'workPrepDetails.scrollMenu.requirements'
									)}
								</Button>
								<Divider
									orientation="vertical"
									variant="middle"
									flexItem
								/>
								<Button onClick={scrollToSafety}>
									{t('workPrepDetails.scrollMenu.safety')}
								</Button>
								<Divider
									orientation="vertical"
									variant="middle"
									flexItem
								/>
								<Button onClick={scrollToSignStatus}>
									{t('workPrepDetails.scrollMenu.signStatus')}
								</Button>
								<Divider
									orientation="vertical"
									variant="middle"
									flexItem
								/>
								<Button onClick={scrollToComments}>
									{t('workPrepDetails.scrollMenu.comments')}
								</Button>
							</Box>
							<Grid
								id="WorkPrepOverview"
								container
								spacing={4}
								sx={{ mb: 4 }}
							>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t('workPrepDetails.event')}
										value={
											currentWorkPrep.workSteps
												? currentWorkPrep.workSteps
												: '-'
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t('workPrepDetails.created')}
										value={
											<PrettyDate
												dateString={
													currentWorkPrep.establishedDate
												}
											/>
										}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={4} sx={{ mb: 4 }}>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.relevantMall'
										)}
										value={
											currentWorkPrep.relevantMall ==
											'Täby Centrum'
												? 'Westfield Täby Centrum'
												: currentWorkPrep.relevantMall
										}
									/>{' '}
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.numberOfPeople'
										)}
										value={
											currentWorkPrep.numPeople
												? currentWorkPrep.numPeople
												: '-'
										}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={4} sx={{ mb: 4 }}>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t('workPrepDetails.workArea')}
										value={
											currentWorkPrep.workArea
												? currentWorkPrep.workArea
												: '-'
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t('workPrepDetails.floor')}
										value={
											currentWorkPrep.floor
												? currentWorkPrep.floor
												: '-'
										}
									/>{' '}
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.storeIdOrName'
										)}
										value={
											currentWorkPrep.storeIdOrName
												? currentWorkPrep.storeIdOrName
												: '-'
										}
									/>
								</Grid>
							</Grid>
							<Divider sx={{ my: 4 }} />
							<Grid container spacing={4} sx={{ mb: 4 }}>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t('workPrepDetails.startDate')}
										value={
											<PrettyDate
												dateString={
													currentWorkPrep.startDate
												}
											/>
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.startingTime'
										)}
										value={
											currentWorkPrep.workingHours
												.startingTime
										}
									/>
								</Grid>
								{currentWorkPrep.filesURLObj
									?.scheduleForTheProject ? (
									currentWorkPrep.filesURLObj
										?.scheduleForTheProject.length > 0 ? (
										<FileUrlSingle
											fileURL={
												currentWorkPrep.filesURLObj
													?.scheduleForTheProject
											}
											title={t(
												'workPrepDetails.scheduleForTheProject'
											)}
											value={
												currentWorkPrep.filesURLObj
													?.scheduleForTheProject
													.length > 0
													? t('common.yes')
													: t('common.no')
											}
										/>
									) : (
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'workPrepDetails.scheduleForTheProject'
												)}
												value={
													currentWorkPrep.filesURLObj
														?.scheduleForTheProject
														? currentWorkPrep
																.filesURLObj
																?.scheduleForTheProject
																.length > 0
															? t('common.yes')
															: t('common.no')
														: t('common.no')
												}
											/>
										</Grid>
									)
								) : (
									<Grid
										item
										sx={{ minWidth: '250px' }}
										xs={3}
									>
										<FieldDisplay
											title={t(
												'workPrepDetails.scheduleForTheProject'
											)}
											value={
												currentWorkPrep.filesURLObj
													?.scheduleForTheProject
													? currentWorkPrep
															.filesURLObj
															?.scheduleForTheProject
															.length > 0
														? t('common.yes')
														: t('common.no')
													: t('common.no')
											}
										/>
									</Grid>
								)}
							</Grid>
							<Grid container spacing={4} sx={{ mb: 4 }}>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t('workPrepDetails.endDate')}
										value={
											<PrettyDate
												dateString={
													currentWorkPrep.endDate
												}
											/>
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t('workPrepDetails.endingTime')}
										value={
											currentWorkPrep.workingHours
												.endingTime
										}
									/>
								</Grid>

								{currentWorkPrep.filesURLObj
									?.willTheWorkTakeLongerThan30DaysCertificate &&
								currentWorkPrep.filesURLObj
									?.willTheWorkTakeLongerThan30DaysCertificate
									.length > 0 ? (
									<FileUrlSingle
										fileURL={
											currentWorkPrep.filesURLObj
												?.willTheWorkTakeLongerThan30DaysCertificate
										}
										title={t(
											'workPrep.willTheWorkTakeLongerThan30DaysQuestionShort'
										)}
										value={
											currentWorkPrep
												.willTheWorkTakeLongerThan30Days
												?.answer
												? t('common.yes')
												: t('common.no')
										}
									/>
								) : (
									<Grid
										item
										sx={{ minWidth: '250px' }}
										xs={3}
									>
										<FieldDisplay
											title={t(
												'workPrep.willTheWorkTakeLongerThan30DaysQuestionShort'
											)}
											value={
												currentWorkPrep
													.willTheWorkTakeLongerThan30Days
													?.answer
													? t('common.yes')
													: t('common.no')
											}
										/>
									</Grid>
								)}
							</Grid>
							<Divider sx={{ my: 4 }} />
							<Grid
								id="WorkPrepCreator"
								container
								spacing={4}
								sx={{ mb: 4 }}
							>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.createdByCompany'
										)}
										value={
											currentWorkPrep.creatorOfWorkPrep
												.company
												? currentWorkPrep
														.creatorOfWorkPrep
														.company
												: '-'
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.createdByPerson'
										)}
										value={
											currentWorkPrep.creatorOfWorkPrep
												.name
												? currentWorkPrep
														.creatorOfWorkPrep.name
												: '-'
										}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={4} sx={{ mb: 4 }}>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.telephoneNumber'
										)}
										value={
											currentWorkPrep.creatorOfWorkPrep
												.telephoneNumber
												? currentWorkPrep
														.creatorOfWorkPrep
														.telephoneNumber
												: '-'
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t('workPrepDetails.email')}
										value={
											currentWorkPrep.creatorOfWorkPrep
												.email
												? currentWorkPrep
														.creatorOfWorkPrep.email
												: '-'
										}
									/>
								</Grid>
							</Grid>
							<Divider sx={{ my: 4 }} />
							<Grid
								id="WorkPrepRequirements"
								container
								spacing={4}
								sx={{ mb: 4 }}
							>
								{currentWorkPrep.constructionWorkWillTakePlace
									.urwBaseU.exists ? (
									currentWorkPrep.filesURLObj
										?.baseUCertificate ? (
										<FileUrlSingle
											fileURL={
												currentWorkPrep.filesURLObj
													?.baseUCertificate
											}
											title={t(
												'workPrepDetails.urwBaseUExists'
											)}
											value={
												currentWorkPrep
													.constructionWorkWillTakePlace
													.urwBaseU.exists
													? t('common.yes')
													: t('common.no')
											}
										/>
									) : (
										<p />
									)
								) : (
									<Grid
										item
										sx={{ minWidth: '250px' }}
										xs={3}
									>
										<FieldDisplay
											title={t(
												'workPrepDetails.urwBaseUExists'
											)}
											value={
												currentWorkPrep
													.constructionWorkWillTakePlace
													.urwBaseU.exists
													? t('common.yes')
													: t('common.no')
											}
										/>
									</Grid>
								)}

								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.urwBaseUName'
										)}
										value={
											currentWorkPrep
												.constructionWorkWillTakePlace
												.urwBaseU.exists
												? currentWorkPrep
														.constructionWorkWillTakePlace
														.urwBaseU.name
												: '-'
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.urwBaseUPhone'
										)}
										value={
											currentWorkPrep
												.constructionWorkWillTakePlace
												.urwBaseU.exists
												? currentWorkPrep
														.constructionWorkWillTakePlace
														.urwBaseU
														.telephoneNumber
												: '-'
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.urwBaseUEmail'
										)}
										value={
											currentWorkPrep
												.constructionWorkWillTakePlace
												.urwBaseU.exists
												? currentWorkPrep
														.constructionWorkWillTakePlace
														.urwBaseU.email
												: '-'
										}
									/>
								</Grid>
							</Grid>

							<Grid container spacing={4} sx={{ mb: 4 }}>
								{currentWorkPrep.constructionWorkWillTakePlace
									.baseP.exists ? (
									currentWorkPrep.filesURLObj
										?.basePCertificate ? (
										<FileUrlSingle
											fileURL={
												currentWorkPrep.filesURLObj
													?.basePCertificate
											}
											title={t(
												'workPrepDetails.basePExists'
											)}
											value={
												currentWorkPrep
													.constructionWorkWillTakePlace
													.baseP.exists
													? t('common.yes')
													: t('common.no')
											}
										/>
									) : (
										<p />
									)
								) : (
									<Grid
										item
										sx={{ minWidth: '250px' }}
										xs={3}
									>
										<FieldDisplay
											title={t(
												'workPrepDetails.basePExists'
											)}
											value={
												currentWorkPrep
													.constructionWorkWillTakePlace
													.baseP.exists
													? t('common.yes')
													: t('common.no')
											}
										/>
									</Grid>
								)}
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t('workPrepDetails.basePName')}
										value={
											currentWorkPrep
												.constructionWorkWillTakePlace
												.baseP.exists
												? currentWorkPrep
														.constructionWorkWillTakePlace
														.baseP.name
												: '-'
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t('workPrepDetails.basePPhone')}
										value={
											currentWorkPrep
												.constructionWorkWillTakePlace
												.baseP.exists
												? currentWorkPrep
														.constructionWorkWillTakePlace
														.baseP.telephoneNumber
												: '-'
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t('workPrepDetails.basePEmail')}
										value={
											currentWorkPrep
												.constructionWorkWillTakePlace
												.baseP.exists
												? currentWorkPrep
														.constructionWorkWillTakePlace
														.baseP.email
												: '-'
										}
									/>
								</Grid>
							</Grid>
							<Divider sx={{ my: 4 }} />
							<Grid
								id="WorkPrepSafety"
								container
								spacing={4}
								sx={{ mb: 4 }}
							>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.insuranceExists'
										)}
										value={
											currentWorkPrep.insurance.exists
												? t('common.yes')
												: t('common.no')
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={9}>
									<FieldDisplay
										title={t(
											'workPrepDetails.insuranceCompanyName'
										)}
										value={
											currentWorkPrep.insurance.exists
												? currentWorkPrep.insurance
														.company
												: '-'
										}
										className="flex-[3_3_0] mr-14 md:mr-20"
									/>
								</Grid>
							</Grid>
							<Divider sx={{ my: 4 }} />
							<Grid
								id="FireHotVentSprinkler"
								container
								spacing={4}
								sx={{ mb: 4 }}
							>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t('workPrep.hotWork')}
										value={
											currentWorkPrep.hotWorkPermission
												.answer
												? t('common.yes')
												: t('common.no')
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrep.fireAlarmWillBeShutOff'
										)}
										value={
											currentWorkPrep
												.fireAlarmWillBeShutOff.answer
												? t('common.yes')
												: t('common.no')
										}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={4} sx={{ mb: 4 }}>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepPDF.switchedOffVentilation'
										)}
										value={
											currentWorkPrep
												.ventilationWillBeShutOff.answer
												? t('common.yes')
												: t('common.no')
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={5}>
									<FieldDisplay
										title={t(
											'workPrepPDF.switchedOffVentilationTimePeriod'
										)}
										value={
											currentWorkPrep
												.ventilationWillBeShutOff.answer
												? currentWorkPrep
														.ventilationWillBeShutOff
														.timePeriod
												: '-'
										}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={4} sx={{ mb: 4 }}>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepPDF.switchedOffSprinklerSystem'
										)}
										value={
											currentWorkPrep
												.sprinklerSystemWillBeShutOff
												.answer
												? t('common.yes')
												: t('common.no')
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={5}>
									<FieldDisplay
										title={t(
											'workPrepPDF.switchedOffSprinklerSystemTimePeriod'
										)}
										value={
											currentWorkPrep
												.sprinklerSystemWillBeShutOff
												.answer
												? currentWorkPrep
														.sprinklerSystemWillBeShutOff
														.timePeriod
												: '-'
										}
									/>
								</Grid>
							</Grid>

							<Divider sx={{ my: 4 }} />
							<Grid container spacing={4} sx={{ mb: 4 }}>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.certainRisks'
										)}
										value={
											currentWorkPrep.certainRisks || '-'
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.remediesForWorkEnvironmentRisks'
										)}
										value={
											currentWorkPrep.measureForWorkEnvironmentRisks ||
											'-'
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t('workPrepDetails.area')}
										value={
											currentWorkPrep.areaWhereWorkWillBePerformed ||
											'-'
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.descriptionOfWorkSteps'
										)}
										value={
											currentWorkPrep.describeWorkSteps ||
											'-'
										}
										className="flex-[2_2_0] mr-7 md:mr-10"
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.additionalResources'
										)}
										value={
											currentWorkPrep.additionalResources ||
											'-'
										}
									/>
								</Grid>
							</Grid>
							{/* Client section */}
							{currentWorkPrep.client && (
								<>
									<Divider sx={{ my: 4 }} />
									<Grid container spacing={4} sx={{ mb: 4 }}>
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'workPrepPDF.clientName'
												)}
												value={
													currentWorkPrep.client.name
												}
											/>
										</Grid>
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'workPrepPDF.clientTelephone'
												)}
												value={
													currentWorkPrep.client
														.telephone
												}
											/>
										</Grid>
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'workPrepPDF.clientEmail'
												)}
												value={
													currentWorkPrep.client.email
												}
											/>
										</Grid>
									</Grid>
								</>
							)}
							<Divider sx={{ my: 4 }} />
							<Grid container spacing={4} sx={{ mb: 4 }}>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.projectManagerExists'
										)}
										value={
											currentWorkPrep.projectManager
												?.exists
												? t('common.yes')
												: t('common.no')
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.projectManagerName'
										)}
										value={
											currentWorkPrep.projectManager
												?.exists
												? currentWorkPrep.projectManager
														.name
												: '-'
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.projectManagerEmail'
										)}
										value={
											currentWorkPrep.projectManager
												?.exists
												? currentWorkPrep.projectManager
														.email
												: '-'
										}
									/>
								</Grid>
								<Grid item sx={{ minWidth: '250px' }} xs={3}>
									<FieldDisplay
										title={t(
											'workPrepDetails.projectManagerPhone'
										)}
										value={
											currentWorkPrep.projectManager
												?.exists
												? currentWorkPrep.projectManager
														.telephoneNumber
												: '-'
										}
									/>
								</Grid>
							</Grid>
							<Divider sx={{ my: 4 }} />
							<Typography
								sx={{
									fontWeight: 600,
									fontSize: '14px',
									mb: 2,
								}}
							>
								{t('workPrepDetails.attachments')}
							</Typography>
							<Grid container columns={3} spacing={3}>
								{currentWorkPrep.filesURLObj
									?.workWithWaterCertificate ? (
									currentWorkPrep.filesURLObj
										?.workWithWaterCertificate.length >
									0 ? (
										<FileUrlSingle
											fileURL={
												currentWorkPrep.filesURLObj
													?.workWithWaterCertificate
											}
											title={t(
												'common.workWithWaterCertificate'
											)}
											value=""
										/>
									) : (
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'common.workWithWaterCertificate'
												)}
												value={'-'}
											/>
										</Grid>
									)
								) : (
									<Grid
										item
										sx={{ minWidth: '250px' }}
										xs={3}
									>
										<FieldDisplay
											title={t(
												'common.workWithWaterCertificate'
											)}
											value={'-'}
										/>
									</Grid>
								)}
								{currentWorkPrep.filesURLObj
									?.liftCardsForPersonalWorkingWithLifts ? (
									currentWorkPrep.filesURLObj
										?.liftCardsForPersonalWorkingWithLifts
										.length > 0 ? (
										<FileUrlSingle
											fileURL={currentWorkPrep.filesURLObj?.liftCardsForPersonalWorkingWithLifts.toString()}
											title={t(
												'common.liftCardsForPersonalWorkingWithLifts'
											)}
											value=""
										/>
									) : (
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'common.liftCardsForPersonalWorkingWithLifts'
												)}
												value={'-'}
											/>
										</Grid>
									)
								) : (
									<Grid
										item
										sx={{ minWidth: '250px' }}
										xs={3}
									>
										<FieldDisplay
											title={t(
												'common.liftCardsForPersonalWorkingWithLifts'
											)}
											value={'-'}
										/>
									</Grid>
								)}
								{currentWorkPrep.filesURLObj
									?.constructionEnvironmentWorkPlan ? (
									currentWorkPrep.filesURLObj
										?.constructionEnvironmentWorkPlan
										.length > 0 ? (
										<FileUrlSingle
											fileURL={currentWorkPrep.filesURLObj?.constructionEnvironmentWorkPlan.toString()}
											title={t(
												'common.constructionEnvironmentWorkPlan'
											)}
											value=""
										/>
									) : (
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'common.constructionEnvironmentWorkPlan'
												)}
												value={'-'}
											/>
										</Grid>
									)
								) : (
									<Grid
										item
										sx={{ minWidth: '250px' }}
										xs={3}
									>
										<FieldDisplay
											title={t(
												'common.constructionEnvironmentWorkPlan'
											)}
											value={'-'}
										/>
									</Grid>
								)}
								{currentWorkPrep.filesURLObj
									?.electricalWorkCertificate ? (
									currentWorkPrep.filesURLObj
										?.electricalWorkCertificate.length >
									0 ? (
										<FileUrlSingle
											fileURL={currentWorkPrep.filesURLObj?.electricalWorkCertificate.toString()}
											title={t(
												'common.electricalWorkCertificate'
											)}
											value=""
										/>
									) : (
										<Grid
											item
											sx={{ minWidth: '250px' }}
											xs={3}
										>
											<FieldDisplay
												title={t(
													'common.electricalWorkCertificate'
												)}
												value={'-'}
											/>
										</Grid>
									)
								) : (
									<Grid
										item
										sx={{ minWidth: '250px' }}
										xs={3}
									>
										<FieldDisplay
											title={t(
												'common.electricalWorkCertificate'
											)}
											value={'-'}
										/>
									</Grid>
								)}
							</Grid>
							<Divider sx={{ my: 4 }} />
							<Box id="WorkPrepSignStatus">
								<FieldDisplay
									title={t('workPrep.signStatus')}
									value={
										<SignStatusList
											workPrep={currentWorkPrep}
										/>
									}
								/>
							</Box>
							<Divider sx={{ my: 4 }} />
							{((currentWorkPrep.lastEditedOn &&
								currentWorkPrep.lastEditedBy) ||
								currentWorkPrep.completionRequest ||
								currentWorkPrep.lastReminded) && (
								<>
									<div className={rowClasses}>
										<FieldDisplay
											title={t('workPrepDetails.updates')}
											value={
												<Updates
													workPrep={currentWorkPrep}
													type="workPrep"
													t={t}
												/>
											}
										/>
									</div>
									<Divider sx={{ my: 4 }} />
								</>
							)}
							<div id="WorkPrepComments">
								<FieldDisplay
									title={t('workPrepDetails.commentSection')}
									value={
										<CommentSection
											data={currentWorkPrep}
										/>
									}
								/>
								<div
									style={{ paddingLeft: 16, paddingTop: 10 }}
								>
									<Button
										startIcon={<AddCommentIcon />}
										variant="outlined"
										onClick={() =>
											setCommentModalIsOpen(
												!commentModalIsOpen
											)
										}
									>
										{t('workPrepDetails.commentWorkPrep')}
									</Button>
								</div>
							</div>
							<Divider sx={{ my: 4 }} />
							{roleActions[
								role as keyof typeof roleActions
							].includes('pdf') && (
								<Button
									fullWidth={true}
									startIcon={<FileOpenIcon />}
									variant="contained"
									onClick={() =>
										navigate(
											`/admin-panel/${currentWorkPrep.id}/pdf`
										)
									}
									sx={{ mt: 2 }}
								>
									{t('workPrepDetails.openAsPDF')}
								</Button>
							)}
							{roleActions[
								role as keyof typeof roleActions
							].includes('fireAlarm') && (
								<Button
									disabled={
										currentWorkPrep.status !== 'Approved'
									}
									fullWidth={true}
									startIcon={<Add />}
									variant="contained"
									onClick={newFireAlarm}
									sx={{ mt: 2 }}
								>
									{t('workPrepDetails.newFireAlarm')}
								</Button>
							)}
							{roleActions[
								role as keyof typeof roleActions
							].includes('edit') && (
								<Button
									fullWidth={true}
									startIcon={<SettingsIcon />}
									variant="contained"
									onClick={() =>
										navigate(
											`/admin-panel/editWorkPrep/${currentWorkPrep.id}`
										)
									}
									sx={{ mt: 2 }}
								>
									{t('workPrepDetails.makeChangesInWorkPrep')}
								</Button>
							)}
							{roleActions[
								role as keyof typeof roleActions
							].includes('remind') && (
								<Button
									fullWidth={true}
									startIcon={<NotificationsActiveIcon />}
									disabled={
										currentWorkPrep.status === 'Approved'
									}
									variant="contained"
									onClick={() => sendSignatureReminder()}
									sx={{ mt: 2 }}
								>
									{t('workPrepDetails.sendReminder')}
								</Button>
							)}
							{roleActions[
								role as keyof typeof roleActions
							].includes('newSignatures') && (
								<Tooltip
									title={t(
										'workPrepDetails.newSignatureRequestTooltip'
									)}
									disableInteractive
								>
									<Button
										fullWidth={true}
										startIcon={<AssignmentTurnedInIcon />}
										variant="contained"
										onClick={() => requestNewSignatures()}
										sx={{ mt: 2 }}
									>
										{t(
											'workPrepDetails.newSignatureRequest'
										)}
									</Button>
								</Tooltip>
							)}
							{roleActions[
								role as keyof typeof roleActions
							].includes('completion') && (
								<Button
									fullWidth={true}
									startIcon={<Repeat />}
									variant="contained"
									onClick={() =>
										setRequestCompletionModalIsOpen(
											!requestCompletionModalIsOpen
										)
									}
									sx={{ mt: 2 }}
								>
									{t(
										'workPrepDetails.completeWorkPrepRequest'
									)}
								</Button>
							)}
							{roleActions[
								role as keyof typeof roleActions
							].includes('signProjectManager') &&
								isUserProjectManager && (
									<Tooltip
										title={
											currentWorkPrep.creatorOfWorkPrep
												.signStatus != 'Signed'
												? t(
														'workPrepDetails.signProjectManagerToolTip'
												  )
												: ''
										}
										disableInteractive
									>
										<div>
											<Button
												fullWidth={true}
												disabled={
													currentWorkPrep
														.projectManager
														.signStatus ===
														'Signed' ||
													!isUserProjectManager
												}
												startIcon={<CheckCircleIcon />}
												variant="contained"
												color="success"
												onClick={
													signWorkPrepProjectManager
												}
												sx={{ mt: 2 }}
											>
												{t(
													'workPrepDetails.signProjectManager'
												)}
											</Button>
										</div>
									</Tooltip>
								)}
							{roleActions[
								role as keyof typeof roleActions
							].includes('changeStatus') && (
								<Tooltip
									title={
										!checkIfSigningIsComplete()
											? t(
													'workPrepDetails.approveTooltip'
											  )
											: ''
									}
									disableInteractive
								>
									<div>
										<Button
											fullWidth={true}
											disabled={
												currentWorkPrep.status ===
													'Approved' ||
												!checkIfSigningIsComplete()
											}
											startIcon={<CheckCircleIcon />}
											variant="contained"
											color="success"
											onClick={() =>
												setApproveModalIsOpen(
													!approveModalIsOpen
												)
											}
											sx={{ mt: 2 }}
										>
											{t('workPrepDetails.approve')}
										</Button>
									</div>
								</Tooltip>
							)}
							{roleActions[
								role as keyof typeof roleActions
							].includes('changeStatus') && (
								<Button
									fullWidth={true}
									disabled={
										currentWorkPrep.status === 'Rejected'
									}
									startIcon={<ThumbDownIcon />}
									variant="contained"
									color="error"
									onClick={() =>
										setRejectModalIsOpen(!rejectModalIsOpen)
									}
									sx={{ mt: 2 }}
								>
									{t('workPrepDetails.rejectWorkPrep')}
								</Button>
							)}
							{roleActions[
								role as keyof typeof roleActions
							].includes('remove') && (
								<Button
									fullWidth={true}
									startIcon={<DeleteIcon />}
									variant="outlined"
									color="error"
									onClick={() =>
										setDeleteModalIsOpen(!deleteModalIsOpen)
									}
									sx={{ mt: 2 }}
								>
									{t('workPrepDetails.deleteWorkPrep')}
								</Button>
							)}
						</Box>
					</PaperLooksContainer>
				</Layout>
			</AdminMenu>
			<Snackbar
				open={snackbarOptions.open}
				autoHideDuration={snackbarOptions.autoHideDuration}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				sx={{ position: 'fixed' }}
			>
				<Alert
					onClose={hideSnackBar}
					severity={snackbarOptions.severity}
					sx={{ width: '100%' }}
				>
					{snackbarOptions.message}
				</Alert>
			</Snackbar>
		</>
	)
}

export default WorkPrepDetails

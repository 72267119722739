import i18n from 'i18next'
import { urw_country } from './constants'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { EN_TRANSLATIONS } from './locales/en_translation'
import { SE_TRANSLATIONS } from './locales/sv_translation'
import { NL_TRANSLATIONS } from './locales/nl_translation'

const translations = {
	SE: {
		se: {
			translation: SE_TRANSLATIONS,
		},
		en: {
			translation: EN_TRANSLATIONS,
		},
	},
	NL: {
		nl: {
			translation: NL_TRANSLATIONS,
		},
		en: {
			translation: EN_TRANSLATIONS,
		},
	},
}

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: true,
		fallbackLng: urw_country.toLowerCase(),
		interpolation: {
			escapeValue: false,
		},
		resources: translations[urw_country],
	})

export default i18n

import { Form, Formik } from 'formik'
import {
	Alert,
	Button,
	Snackbar,
	Stepper,
	Step,
	StepLabel,
	CircularProgress,
	Grid,
} from '@mui/material'
import * as Yup from 'yup'
import { useState } from 'react'
import {
	LocationState,
	SnackbarData,
	WorkPrepAnswers,
	selectedUserDocuments,
} from '../types'
import { useMutation } from '@apollo/client'
import { SUBMIT_WORK_PREP } from '../graphql/mutations/workPreps'
import { useTranslation } from 'react-i18next'
import DocumentPDF from '../components/admin/pdf/DocumentPDF'
import { pdf } from '@react-pdf/renderer'
import WorkForm from '../components/forms/WorkForm'
import CreatorForm from '../components/forms/CreatorForm'
import ArrowForward from '@mui/icons-material/ArrowForward'
import ArrowBack from '@mui/icons-material/ArrowBack'
import RequirementsForm from '../components/forms/RequirementsForm'
import SignForm from '../components/forms/SignForm'
import SignStatus from '../components/forms/signStatus'
import { useLocation, useParams } from 'react-router-dom'
import { ScrollToError } from '../components/formik/ScrollToError'
import PaperLooksContainer from '../components/common/PaperLooksContainer'
import DevButton from '../components/common/DevButton'
import { urw_country } from '../constants'

const WorkPrep = () => {
	const { t } = useTranslation()
	const location = useLocation()
	const state = location.state as LocationState

	const isCreatedLoggedIn = state && state.user ? true : false

	const params = useParams()

	const [submitWorkPrep] = useMutation(SUBMIT_WORK_PREP)

	const [stepperStep, setStepperStep] = useState<number>(
		params.signStatus ? 4 : 0
	)

	const [snackbarData, setSnackbarData] = useState<SnackbarData>({
		message: 'This is a success message!',
		severity: 'success',
		open: false,
	})

	const isFirstStep = stepperStep === 0
	const isLastStep = stepperStep === 3

	function _renderStepContent(step: number) {
		switch (step) {
			case 0:
				return <CreatorForm />
			case 1: {
				return (
					<WorkForm
						setSnackbarData={setSnackbarData}
						isCreatedLoggedIn={isCreatedLoggedIn}
					/>
				)
			}
			case 2:
				return (
					<RequirementsForm
						setSnackbarData={setSnackbarData}
						isCreatedLoggedIn={isCreatedLoggedIn}
					/>
				)
			case 3:
				return <SignForm />
			case 4:
				return (
					<SignStatus
						signStatus={params.signStatus}
						setSnackbarData={setSnackbarData}
					/>
				)
			default:
				return <div>Not Found</div>
		}
	}

	const handleSubmit = async (values: any, actions: any) => {
		if (isLastStep) {
			await workPrepSubmit(values, actions.resetForm)
		} else {
			setStepperStep((prevActiveStep) => prevActiveStep + 1)
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			})
			actions.setTouched({})
		}
		actions.setSubmitting(false)
	}

	const backButtonClicked = () => {
		setStepperStep(stepperStep - 1)
	}

	const closeSnackbarHandler = () => {
		const tempSnackbarData = { ...snackbarData }
		tempSnackbarData.open = false
		setSnackbarData(tempSnackbarData)
	}

	const today = new Date()
	today.setHours(0, 0, 0, 0)

	const workPrepValidationSchema = [
		Yup.object({
			createdByCompany: Yup.string().required(t('common.required')),
			createdByPersonName: Yup.string().required(t('common.required')),
			telephoneNumberToCreator: Yup.string().required(
				t('common.required')
			),
			emailToCreator: Yup.string()
				.email(t('common.invalidEmailAddress'))
				.required(t('common.required')),
			iHaveRead: Yup.bool().oneOf([true], t('common.required')),
		}),
		Yup.object({
			workSteps: Yup.string().required(t('common.required')),
			workArea: Yup.string().required(t('common.required')),
			relevantMall: Yup.string().required(t('common.required')),

			floor: Yup.string().required(t('common.required')),
			storeIdOrName: Yup.string().required(t('common.required')),
			startDate: Yup.date()
				.typeError(t('common.invalidDate'))
				.required(t('common.required'))
				.min(today, t('common.beforeToday')),
			endDate: Yup.date()
				.typeError(t('common.invalidDate'))
				.required(t('common.required'))
				.min(Yup.ref('startDate'), t('common.beforeStart')),
			startingTime: Yup.date()
				.typeError(t('common.invalidTime'))
				.required(t('common.required')),
			endingTime: Yup.date()
				.typeError(t('common.invalidTime'))
				.required(t('common.required')),
			numPeople: Yup.number()
				.min(1, t('workPrep.validation.atLeastOnePerson'))
				.integer(t('common.mustBeInteger'))
				.typeError(t('common.mustBeInteger'))
				.required(t('common.required')),
			insuranceCompanyName: Yup.string().required(t('common.required')),
			insuranceContractNumber: Yup.string().required(
				t('common.required')
			),
			nameToProjectManager: Yup.string().when('projectManagerExists', {
				is: true,
				then: Yup.string().required(t('common.required')),
			}),
			telephoneToProjectManager: Yup.string().when(
				'projectManagerExists',
				{
					is: true,
					then: Yup.string().required(t('common.required')),
				}
			),
			emailToProjectManager: Yup.string()
				.email(t('common.invalidEmailAddress'))
				.when('projectManagerExists', {
					is: true,
					then: Yup.string().required(t('common.required')),
				}),
			workWithWaterCertificate: Yup.lazy((val, context) => {
				if (
					//Work with water will be done and no user document is selected
					context.parent.workWithWaterWillBeDone &&
					!context.parent.selectedUserDocuments
						?.workWithWaterCertificate
				) {
					return Yup.array()
						.required()
						.min(1, t('common.fileRequired'))
				}
				return Yup.mixed().nullable(true)
			}),
			electricalWorkCertificate: Yup.lazy((val, context) => {
				if (
					//Electrical work will be done and no user document is selected
					context.parent.electricalWorkWillBeDone &&
					!context.parent.selectedUserDocuments
						?.electricalWorkCertificate
				) {
					return Yup.array()
						.required()
						.min(1, t('common.fileRequired'))
				}
				return Yup.mixed().nullable(true)
			}),
			constructionEnvironmentWorkPlan: Yup.mixed().when(
				'constructionWorkWillTakePlace',
				{
					is: true,
					then: Yup.array()
						.required()
						.min(1, t('common.fileRequired')),
					otherwise: Yup.mixed().nullable(true),
				}
			),
			baseUCertificate: Yup.lazy((val, context) => {
				if (
					//Bas-U exists and no user document is selected
					context.parent.baseUAndBasePExists &&
					!context.parent.selectedUserDocuments?.baseUCertificate &&
					context.parent.constructionWorkWillTakePlace
				) {
					return Yup.array()
						.required()
						.min(1, t('common.fileRequired'))
				}
				return Yup.mixed().nullable(true)
			}),
			basePCertificate: Yup.lazy((val, context) => {
				if (
					//Bas-P exists and no user document is selected
					context.parent.baseUAndBasePExists &&
					!context.parent.selectedUserDocuments?.basePCertificate &&
					context.parent.constructionWorkWillTakePlace
				) {
					return Yup.array()
						.required()
						.min(1, t('common.fileRequired'))
				}
				return Yup.mixed().nullable(true)
			}),
			nameToURWBaseU: Yup.string().when('baseUAndBasePExists', {
				is: true,
				then: Yup.string().required(t('common.required')),
			}),
			telephoneToURWBaseU: Yup.string().when('baseUAndBasePExists', {
				is: true,
				then: Yup.string().required(t('common.required')),
			}),
			emailToURWBaseU: Yup.string()
				.email(t('common.invalidEmailAddress'))
				.when('baseUAndBasePExists', {
					is: true,
					then: Yup.string().required(t('common.required')),
				}),
			nameToBaseP: Yup.string().when('baseUAndBasePExists', {
				is: true,
				then: Yup.string().required(t('common.required')),
			}),
			telephoneToBaseP: Yup.string().when('baseUAndBasePExists', {
				is: true,
				then: Yup.string().required(t('common.required')),
			}),
			emailToBaseP: Yup.string()
				.email(t('common.invalidEmailAddress'))
				.when('baseUAndBasePExists', {
					is: true,
					then: Yup.string().required(t('common.required')),
				}),
			willTheWorkTakeLongerThan30DaysCertificate: Yup.mixed().when(
				'willTheWorkTakeLongerThan30Days',
				{
					is: true,
					then: Yup.array()
						.required()
						.min(1, t('common.fileRequired')),
					otherwise: Yup.mixed().nullable(true),
				}
			),
			clientName: Yup.string().required(t('common.required')),
			clientTelephone: Yup.string().required(t('common.required')),
			clientEmail: Yup.string()
				.required(t('common.required'))
				.email(t('common.invalidEmailAddress')),
		}),
		Yup.object({
			hotWorkPermission: Yup.boolean(),
			hotWorkPermitManager: Yup.string(),
			liftCardsForPersonalWorkingWithLifts: Yup.array().when(
				['scissorLift', 'skylift', 'selectedUserDocuments'],
				{
					is: (
						scissorLift: boolean,
						skylift: boolean,
						selectedUserDocuments: selectedUserDocuments
					) =>
						(scissorLift === true || skylift === true) &&
						selectedUserDocuments
							.liftCardsForPersonalWorkingWithLifts.length == 0,
					then: Yup.array()
						.required()
						.min(1, t('common.fileRequired')),
					otherwise: Yup.array().required(),
				}
			),
		}),
		Yup.object({
			iHaveReadConfirm: Yup.bool().oneOf([true], t('common.required')),
			/*
			confirmPrivacyPolicy: Yup.bool().oneOf(
				[true],
				t('common.required')
			),
			*/
		}),
	]

	const currentValidationSchema = workPrepValidationSchema[stepperStep]

	const initialFormikValues = {
		workSteps: '',
		relevantMall: '',
		createdByCompany: '',
		pdf_file: '',
		createdByPersonName: '',
		telephoneNumberToCreator: '',
		emailToCreator: '',
		workArea: '',
		floor: '',
		numPeople: '',
		storeIdOrName: '',
		startDate: '',
		endDate: '',
		startingTime: '', // TODO: Parse:a detta rätt vid submit
		endingTime: '',

		projectManagerExists: false,
		nameToProjectManager: '',
		telephoneToProjectManager: '',
		emailToProjectManager: '',

		hotWorkPermission: false,
		hotWorkPermitManager: '',

		fireAlarmWillBeShutOff: true,
		whenWillFireAlarmBeShutOff: '',

		workWithWaterWillBeDone: false,
		workWithWaterCertificate: [],

		ventilationWillBeShutOff: false,
		whenWillVentilationBeShutOff: '',

		electricalWorkWillBeDone: false,
		electricalWorkCertificate: [],

		sprinklerSystemWillBeShutOff: false,
		whenWillSprinklerSystemBeShutOff: '',

		constructionWorkWillTakePlace: true,
		constructionEnvironmentWorkPlan: [],

		urwBaseUExists: false,
		nameToURWBaseU: '',
		telephoneToURWBaseU: '',
		emailToURWBaseU: '',

		baseUCertificate: [],
		basePCertificate: [],
		scheduleForTheProject: [],

		clientName: '',
		clientTelephone: '',
		clientEmail: '',

		willTheWorkTakeLongerThan30Days: false,
		willTheWorkTakeLongerThan30DaysCertificate: [],

		basePExists: false,
		nameToBaseP: '',
		telephoneToBaseP: '',
		emailToBaseP: '',

		baseUAndBasePExists: false,

		insuranceCompanyName: '',
		insuranceContractNumber: '',

		fallRisk: false,
		hazardousChemOrBioSubstances: false,
		heavyLifts: false,
		passingVehicleTraffic: false,
		dust: false,
		vibrationsOrnoise: false,
		fireHazard: false,
		otherCertainRisk: '',

		restaurant: false,
		store: false,
		shoppingMallAisle: false,
		garage: false,
		technicalSpace: false,
		serviceCorridor: false,
		workOutsideFacade: false,
		squareOrEntrance: false,
		alreadyFilledInWithVGPlan: false,

		measureForWorkEnvironmentRisks: '',

		describeWorkSteps: '',

		scissorLift: false,
		skylift: false,
		container: false,
		typeApprovedLadder: false,
		otherAdditionalResource: '',
		liftCardsForPersonalWorkingWithLifts: [],

		iHaveRead: false,
		iHaveReadConfirm: false,
		confirmPrivacyPolicy: false,
		selectedUserDocuments: {
			baseUCertificate: '',
			basePCertificate: '',
			electricalWorkCertificate: '',
			workWithWaterCertificate: '',
			liftCardsForPersonalWorkingWithLifts: [],
		},
	}

	const validateFiles = (values: any) => {
		if (values.constructionWorkWillTakePlace) {
			for (const file of values.constructionEnvironmentWorkPlan) {
				if (file.type !== 'application/pdf') {
					return false
				}
			}
		}
		if (values.baseUAndBasePExists) {
			for (const file of values.baseUCertificate) {
				if (file.type !== 'application/pdf') {
					return false
				}
			}
		}
		if (values.baseUAndBasePExists) {
			for (const file of values.basePCertificate) {
				if (file.type !== 'application/pdf') {
					return false
				}
			}
		}
		return true
	}

	// TODO: Make snackbar global component with Recoil.
	const workPrepSubmit = async (values: any, resetForm: any) => {
		console.log(values)
		const certainRisksArray = [
			'fallRisk',
			'hazardousChemOrBioSubstances',
			'heavyLifts',
			'passingVehicleTraffic',
			'dust',
			'vibrationsOrnoise',
			'fireHazard',
		]
			.filter((risk) => values[risk])
			.map((risk) => t(`workPrep.certainRisks.${risk}`))

		const areaWhereWorkWillBePerformed = [
			'restaurant',
			'store',
			'shoppingMallAisle',
			'garage',
			'technicalSpace',
			'serviceCorridor',
			'workOutsideFacade',
			'squareOrEntrance',
			'alreadyFilledInWithVGPlan',
		]
			.filter((area) => values[area])
			.map((area) => t(`workPrep.areaWhereWorkWillBePerformed.${area}`))
			.join(', ')

		const additionalResourcesArray = [
			'scissorLift',
			'skylift',
			'container',
			'typeApprovedLadder',
		]
			.filter((additionalResource) => values[additionalResource])
			.map((additionalResource) =>
				t(`workPrep.additionalResources.${additionalResource}`)
			)

		let startingTimeHours = `${values.startingTime.getHours()}`,
			startingTimeMinutes = `${values.startingTime.getMinutes()}`,
			endingTimeHours = `${values.endingTime.getHours()}`,
			endingTimeMinutes = `${values.endingTime.getMinutes()}`

		if (parseInt(startingTimeHours) < 10) {
			startingTimeHours = `0${startingTimeHours}`
		}

		if (parseInt(startingTimeMinutes) < 10) {
			startingTimeMinutes = `0${startingTimeMinutes}`
		}

		if (parseInt(endingTimeHours) < 10) {
			endingTimeHours = `0${endingTimeHours}`
		}

		if (parseInt(endingTimeMinutes) < 10) {
			endingTimeMinutes = `0${endingTimeMinutes}`
		}

		const workPrepAnswers: WorkPrepAnswers = {
			id: '',
			urw_country: urw_country,
			useParallelSigning: true,
			status: '',
			statusChangedBy: '',
			statusChangedOn: '',
			workSteps: values.workSteps,
			relevantMall: values.relevantMall,
			creatorOfWorkPrep: {
				notificationStatus: 'pending',
				company: values.createdByCompany,
				name: values.createdByPersonName,
				telephoneNumber: values.telephoneNumberToCreator,
				email: values.emailToCreator,
				signStatus: '',
				signStatusChangedOn: '',
				signatureType: '',
			},
			establishedDate: new Date(),
			workArea: values.workArea,
			floor: values.floor,
			storeIdOrName: values.storeIdOrName,
			startDate: values.startDate!,
			endDate: values.endDate!,
			workingHours: {
				startingTime: `${startingTimeHours}:${startingTimeMinutes}`,
				endingTime: `${endingTimeHours}:${endingTimeMinutes}`,
			},
			numPeople: values.numPeople.toString(),
			client: {
				name: values.clientName,
				telephone: values.clientTelephone,
				email: values.clientEmail,
			},
			projectManager: {
				notificationStatus: 'pending',
				exists: values.projectManagerExists,
				name: values.nameToProjectManager,
				telephoneNumber: values.telephoneToProjectManager,
				email: values.emailToProjectManager,
				signStatus: '',
				signStatusChangedOn: '',
				signatureType: '',
			},
			hotWorkPermission: {
				answer: values.hotWorkPermission,
				hotWorkPermitManager: values.hotWorkPermitManager,
			},
			fireAlarmWillBeShutOff: {
				answer: values.fireAlarmWillBeShutOff,
				timePeriod: values.whenWillFireAlarmBeShutOff,
			},
			workWithWaterWillBeDone: {
				answer: values.workWithWaterWillBeDone,
			},
			ventilationWillBeShutOff: {
				answer: values.ventilationWillBeShutOff,
				timePeriod: values.whenWillVentilationBeShutOff,
			},
			electricalWorkWillBeDone: {
				answer: values.electricalWorkWillBeDone,
			},

			sprinklerSystemWillBeShutOff: {
				answer: values.sprinklerSystemWillBeShutOff,
				timePeriod: values.whenWillSprinklerSystemBeShutOff,
			},

			constructionWorkWillTakePlace: {
				answer: values.constructionWorkWillTakePlace,

				urwBaseU: {
					exists: values.baseUAndBasePExists,
					notificationStatus: 'pending',
					name: values.nameToURWBaseU,
					telephoneNumber: values.telephoneToURWBaseU,
					email: values.emailToURWBaseU,
					signStatus: '',
					signStatusChangedOn: '',
					signatureType: '',
				},
				baseP: {
					exists: values.baseUAndBasePExists,
					notificationStatus: 'pending',
					name: values.nameToBaseP,
					telephoneNumber: values.telephoneToBaseP,
					email: values.emailToBaseP,
					signStatus: '',
					signStatusChangedOn: '',
					signatureType: '',
				},
			},
			willTheWorkTakeLongerThan30Days: {
				answer: values.willTheWorkTakeLongerThan30Days,
			},
			insurance: {
				//exists could be removed, and company could be split up to two fields.
				exists: true,
				company: `${values.insuranceCompanyName}: ${values.insuranceContractNumber}`,
			},

			certainRisks: [...certainRisksArray, values.otherCertainRisk].join(
				', '
			),

			measureForWorkEnvironmentRisks:
				values.measureForWorkEnvironmentRisks,

			areaWhereWorkWillBePerformed,

			describeWorkSteps: values.describeWorkSteps,
			archived: {
				isArchived: false,
				manuallySet: false,
			},

			additionalResources: [
				...additionalResourcesArray,
				values.otherAdditionalResource,
			].join(', '),
			lastEditedOn: '',
			lastEditedBy: '',
			isCreatedLoggedIn: {
				answer: isCreatedLoggedIn,
				email: isCreatedLoggedIn ? state.user! : '',
			},
		}

		// If a user fills in BaseU and BaseP and then unchecks "constructionWorkWillTakePlace", the information is still there. This whipes it.
		if (!values.constructionWorkWillTakePlace) {
			workPrepAnswers.constructionWorkWillTakePlace = {
				answer: false,

				urwBaseU: {
					exists: false,
					notificationStatus: '',
					name: '',
					telephoneNumber: '',
					email: '',
					signStatus: '',
					signStatusChangedOn: '',
					signatureType: '',
				},
				baseP: {
					exists: false,
					notificationStatus: '',
					name: '',
					telephoneNumber: '',
					email: '',
					signStatus: '',
					signStatusChangedOn: '',
					signatureType: '',
				},
			}
		}

		console.log('Validating files')

		//Check if all files are PDF
		if (validateFiles(values)) {
			setSnackbarData({
				open: true,
				severity: 'info',
				message: t('workPrep.submittingWorkPrep'),
			})

			const workPrepBlob = await pdf(
				<DocumentPDF
					currentWorkPrep={workPrepAnswers}
					customLang="sv"
				/>
			).toBlob()

			const workPrepPDF = new File([workPrepBlob], 'filename.pdf')

			console.log('Created PDF:')
			console.log(workPrepPDF)

			setSnackbarData({
				open: true,
				severity: 'info',
				message: t('workPrep.uploadingFiles'),
			})

			const res = await submitWorkPrep({
				//TODO skicka med array av filer
				variables: {
					workPrepAnswers: JSON.stringify(workPrepAnswers),
					workPrepPDF,
					workWithWaterCertificate: values.workWithWaterWillBeDone
						? values.workWithWaterCertificate
						: [],
					electricalWorkCertificate: values.electricalWorkWillBeDone
						? values.electricalWorkCertificate
						: [],
					constructionEnvironmentWorkPlan:
						values.constructionWorkWillTakePlace
							? values.constructionEnvironmentWorkPlan
							: [],
					baseUCertificate: values.baseUAndBasePExists
						? values.baseUCertificate
						: [],
					basePCertificate: values.baseUAndBasePExists
						? values.basePCertificate
						: [],
					willTheWorkTakeLongerThan30DaysCertificate:
						values.willTheWorkTakeLongerThan30Days
							? values.willTheWorkTakeLongerThan30DaysCertificate
							: [],
					scheduleForTheProject: values.scheduleForTheProject,
					liftCardsForPersonalWorkingWithLifts:
						values.liftCardsForPersonalWorkingWithLifts,
					selectedUserDocuments: JSON.stringify(
						values.selectedUserDocuments
					),
				},
			})

			if (res.data.submitWorkPrep === 'Assently error') {
				setSnackbarData({
					message: t('workPrep.assentlyError'),
					severity: 'error',
					open: true,
				})
			} else {
				setSnackbarData({
					message: t('workPrep.workPrepSubmitted'),
					severity: 'success',
					open: true,
				})
				resetForm()
				try {
					const redirectUrl = new URL(res.data.submitWorkPrep)
					window.location.replace(redirectUrl)
				} catch {
					setSnackbarData({
						message: t('workPrep.urlError'),
						severity: 'error',
						open: true,
					})
				}
			}
		} else {
			setSnackbarData({
				message: t('workPrep.onlyPDF'),
				severity: 'error',
				open: true,
			})
		}
	}

	const steps = [
		t('workPrep.stepper.creator'),
		t('workPrep.stepper.work'),
		t('workPrep.stepper.requirements'),
		t('workPrep.stepper.sign'),
	]

	//Used to indicate on the stepper that the signature is not completed
	const stepperShouldNotDisplayDone =
		params.signStatus != null && params.signStatus != 'completed'

	return (
		<>
			<PaperLooksContainer backBtnPath="/" disabled={!isFirstStep} cancel>
				<Grid
					container
					paddingX={{ xs: 0, sm: 5 }}
					paddingY={5}
					direction={'column'}
				>
					<Grid item>
						<h1>
							{isCreatedLoggedIn
								? t('workPrep.title') + ': ' + state.user!
								: t('workPrep.title')}
						</h1>

						<p className="italic mb-9">{t('workPrep.introText')}</p>
					</Grid>

					<Grid item marginBottom={'50px'} marginTop={'30px'}>
						<Stepper
							activeStep={
								stepperShouldNotDisplayDone ? 3 : stepperStep
							}
							alternativeLabel
						>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							))}
						</Stepper>
					</Grid>

					<Formik
						initialValues={initialFormikValues}
						onSubmit={handleSubmit}
						validationSchema={currentValidationSchema}
					>
						{({ isSubmitting }) => (
							<Form id={'1'}>
								<ScrollToError />
								{_renderStepContent(stepperStep)}
								{stepperStep < 4 && (
									<Grid
										container
										justifyContent="space-between"
										alignItems="center"
										marginTop={'20px'}
									>
										<Grid item>
											<Button
												variant="outlined"
												onClick={backButtonClicked}
												disabled={stepperStep === 0}
												startIcon={<ArrowBack />}
												sx={{ width: 150, height: 50 }}
											>
												{t('workPrep.back')}
											</Button>
										</Grid>
										<Grid item>
											<DevButton />
										</Grid>
										<Grid item>
											<Button
												disabled={isSubmitting}
												type="submit"
												variant="contained"
												endIcon={
													isSubmitting ? (
														<CircularProgress
															size={20}
														/>
													) : (
														<ArrowForward />
													)
												}
												sx={{ width: 150, height: 50 }}
											>
												{isLastStep
													? t('workPrep.sign')
													: t('workPrep.continue')}
											</Button>
										</Grid>
									</Grid>
								)}
							</Form>
						)}
					</Formik>
				</Grid>
			</PaperLooksContainer>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={snackbarData.open}
				onClose={closeSnackbarHandler}
				key={'bottom center'}
			>
				<Alert
					onClose={closeSnackbarHandler}
					severity={snackbarData.severity}
					sx={{ width: '100%' }}
				>
					{/* Show loading animation on text if form is being uploaded */}
					{snackbarData.message === t('workPrep.uploadingFiles') ? (
						<span className="loading-dots">
							{snackbarData.message}
						</span>
					) : (
						snackbarData.message
					)}
				</Alert>
			</Snackbar>
		</>
	)
}

export default WorkPrep

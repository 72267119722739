import React from 'react'
import { useFormikContext, FormikValues } from 'formik'
import Button from '@mui/material/Button'
import { urw_country } from '../../constants'

interface DummyData {
	[key: string]: string | boolean | number | Date

	createdByCompany: string
	createdByPersonName: string
	telephoneNumberToCreator: string
	emailToCreator: string
	iHaveRead: boolean
	workSteps: string
	relevantMall: string
	workArea: string
	floor: string
	storeIdOrName: string
	numPeople: number
	insuranceCompanyName: string
	insuranceContractNumber: string
	startDate: Date
	startingTime: Date
	endDate: Date
	endingTime: Date
	projectManagerExists: boolean
	nameToProjectManager: string
	telephoneToProjectManager: string
	emailToProjectManager: string
	urwBaseUExists: boolean
	nameToURWBaseU: string
	telephoneToURWBaseU: string
	emailToURWBaseU: string
	basePExists: boolean
	nameToBaseP: string
	telephoneToBaseP: string
	emailToBaseP: string
}

const DevButton: React.FC = () => {
	const { setValues } = useFormikContext<FormikValues>()

	const dummyData: DummyData = {
		createdByCompany: 'Dummy Company',
		createdByPersonName: 'John Doe',
		telephoneNumberToCreator: '123456789',
		emailToCreator: 'isacakessonjansen@gmail.com',
		iHaveRead: true,
		workSteps: 'Description of worksteps...',
		relevantMall:
			urw_country === 'SE' ? 'Nacka Forum' : 'Stadshart Zoetermeer',
		workArea: 'Area 51',
		floor: '1',
		storeIdOrName: 'Store 101',
		numPeople: 5,
		insuranceCompanyName: 'Insurance Co',
		insuranceContractNumber: '12345',
		startDate: new Date('2025-10-17'),
		startingTime: new Date('2025-10-17 04:20'),
		endDate: new Date('2026-03-05'),
		endingTime: new Date('2026-03-05 13:37'),
		clientName: 'WeKnowIT AB',
		clientTelephone: '987654321',
		clientEmail: 'isac@weknowit.nu',

		projectManagerExists: true,
		nameToProjectManager: 'Project Manager Name',
		telephoneToProjectManager: '123456789',
		emailToProjectManager:
			urw_country === 'SE'
				? 'isac.akessonjansen@weknowit.se'
				: 'isac.akessonjansen@weknowit.nu',

		constructionWorkWillTakePlace: true,

		baseUAndBasePExists: urw_country === 'SE',

		urwBaseUExists: urw_country === 'SE',
		nameToURWBaseU: urw_country === 'SE' ? 'URW Base U Name' : '',
		telephoneToURWBaseU: urw_country === 'SE' ? '123456789' : '',
		emailToURWBaseU: urw_country === 'SE' ? 'isac.jansen@cffc.se' : '',

		basePExists: urw_country === 'SE',
		nameToBaseP: urw_country === 'SE' ? 'Base P Name' : '',
		telephoneToBaseP: urw_country === 'SE' ? '987654321' : '',
		emailToBaseP: urw_country === 'SE' ? 'isac.jansen@dtek.se' : '',
	}

	const fillFormWithDummyData = () => {
		setValues((prevValues) => ({
			...prevValues,
			...Object.keys(dummyData).reduce((acc, key) => {
				acc[key] = dummyData[key] as string | boolean | number
				return acc
			}, {} as DummyData),
		}))
	}

	if (process.env.NODE_ENV !== 'development') {
		return null // Only show in development mode
	}

	return (
		<Button onClick={fillFormWithDummyData}>
			Fill Form with Dummy Data
		</Button>
	)
}

export default DevButton

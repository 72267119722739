import { DataGrid, GridRowParams, GridColDef } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { AdminPanelItem } from '../../pages/admin/AdminPanel'
import { FireAlarmItem } from '../../pages/admin/FireAlarmPanel'
import { ServiceWorkItem } from '../../pages/admin/ServiceWorkPanel'
import { UserItem } from '../../pages/admin/UsersPanel'

interface props {
	data: AdminPanelItem[] | FireAlarmItem[] | ServiceWorkItem[] | UserItem[]
	columns: GridColDef[]
	type:
		| 'admin-panel'
		| 'fire-alarm-panel'
		| 'service-work-panel'
		| 'user-panel'
		| 'work-history'
	disableClick?: boolean
}

const CustomDataGrid = ({ data, columns, type, disableClick }: props) => {
	const navigate = useNavigate()

	return (
		<DataGrid
			rows={data}
			columns={columns}
			pageSize={type === 'work-history' ? 5 : 10}
			autoHeight={true}
			rowHeight={60}
			headerHeight={80}
			hideFooterSelectedRowCount={true}
			disableColumnMenu
			sx={{
				'& .MuiDataGrid-sortIcon': {
					color: 'white',
				},
				backgroundColor: 'white',
				'.MuiDataGrid-columnSeparator': {
					display: 'none',
				},
				'.MuiDataGrid-columnHeaders': {
					borderRadius: '10px',
					backgroundColor: 'primary.main',
				},
				'.MuiDataGrid-columnHeader': {
					backgroundColor: 'primary.main',
				},
				'.MuiDataGrid-columnHeaderTitle': {
					color: 'white',
				},
				'&.MuiDataGrid-root': {
					backgroundColor: 'rgb(249 250 251)',
					border: 'none',
				},
				'& .MuiDataGrid-virtualScrollerRenderZone': {
					'& .MuiDataGrid-row': {
						'&:nth-of-type(2n)': {
							backgroundColor: 'white',
						},
						'&:hover': {
							background: ' #EBF0F4 !important',
							cursor: `${disableClick ? 'default' : 'pointer'}`,
						},
					},
				},
			}}
			rowsPerPageOptions={[10]}
			onRowClick={(params: GridRowParams) => {
				if (!disableClick) navigate(`/${type}/${params.row.id}`)
			}}
		/>
	)
}

export default CustomDataGrid

import { Button, SxProps } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@mui/icons-material/Language'
import { urw_country } from '../../constants'

interface Props {
	sx?: SxProps
	variant?: 'text' | 'outlined' | 'contained'
	icon?: boolean
}

type LanguageObject = {
	[key: string]: { nativeName: string }
}

const ChangeLanguageButton = ({ sx, variant, icon }: Props) => {
	const { i18n } = useTranslation()

	const languages: { [key: string]: LanguageObject } = {
		SE: {
			se: { nativeName: 'Svenska' },
			en: { nativeName: 'English' },
		},
		NL: {
			nl: { nativeName: 'Nederlands' },
			en: { nativeName: 'English' },
		},
	}

	const getOtherLanguage = () => {
		if (urw_country === 'SE') {
			const resolvedLanguage = i18n.resolvedLanguage as 'se' | 'en'

			return resolvedLanguage === 'se' ? 'en' : 'se'
		} else if (urw_country === 'NL') {
			const resolvedLanguage = i18n.resolvedLanguage as 'nl' | 'en'

			return resolvedLanguage === 'nl' ? 'en' : 'nl'
		} else {
			return 'en'
		}
	}

	const initialLanguage = getOtherLanguage()
	const [otherLanguage, setOtherLanguage] = useState<'se' | 'en' | 'nl'>(
		initialLanguage
	)

	useEffect(() => {
		const newOtherLanguage = getOtherLanguage()
		setOtherLanguage(newOtherLanguage)
	}, [i18n.resolvedLanguage])

	return (
		<Button
			type="submit"
			onClick={() => i18n.changeLanguage(otherLanguage)}
			sx={sx}
			variant={variant}
			startIcon={icon ? <LanguageIcon /> : null}
		>
			{languages[urw_country][otherLanguage].nativeName}
		</Button>
	)
}

export default ChangeLanguageButton

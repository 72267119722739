import { PDFViewer } from '@react-pdf/renderer'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import mosIcon from '../../resources/mos.png'
import nackaForumIcon from '../../resources/nackaForum.png'
import tabyCentrumIcon from '../../resources/tabyCentrum.png'
import mallOfTheNetherlandsIcon from '../../resources/mallOfTheNetherlands.png'
import amstelveenIcon from '../../resources/amstelveen.png'
import zoetermeerIcon from '../../resources/zoetermeer.png'
import { pdf } from '@react-pdf/renderer'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
	WORK_PREP_BY_ID,
	GET_BASE64_FROM_FILES,
} from '../../graphql/queries/workPreps'
import { WorkPrepAnswers } from '../../types'
import DocumentPDF from '../../components/admin/pdf/DocumentPDF'
import DocumentPDF_NL from '../../components/admin/pdf/netherlands/DocumentPDF_NL'
import { useQuery } from '@apollo/client'
import PDFMerger from 'pdf-merger-js/browser'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../components/common/LoadingSpinner'
import NotLoggedIn from '../../components/common/NotLoggedIn'
import { useAuth } from '../../hooks/useAuth'
import { urw_country } from '../../constants'

export const mallToImg = {
	'Nacka Forum': nackaForumIcon,
	'Mall of Scandinavia': mosIcon,
	'Täby Centrum': tabyCentrumIcon,
	'Westfield Mall of Scandinavia': mosIcon,
	'Westfield Täby Centrum': tabyCentrumIcon,
	'Mall of the Netherlands': mallOfTheNetherlandsIcon,
	'Stadshart Amstelveen': amstelveenIcon,
	'Stadshart Zoetermeer': zoetermeerIcon,
}

function getLocalPDF(currentWorkPrep: any) {
	if (urw_country === 'NL') {
		return <DocumentPDF_NL currentWorkPrep={currentWorkPrep} />
	} else if (urw_country === 'SE') {
		return <DocumentPDF currentWorkPrep={currentWorkPrep} customLang="sv" />
	} else {
		// Swedish is fallback
		return <DocumentPDF currentWorkPrep={currentWorkPrep} customLang="sv" />
	}
}

const WorkPrepPDF = (): JSX.Element => {
	const navigate = useNavigate()
	const params = useParams()
	const [errorOccured, setErrorOccured] = useState(false)
	const [mergedPdfUrl, setMergedPdfUrl] = useState('')
	const [currentWorkPrep, setCurrentWorkPrep] =
		useState<null | WorkPrepAnswers>(null)

	const { role, jwt } = useAuth()
	const { t } = useTranslation()
	const { loading, data } = useQuery(WORK_PREP_BY_ID, {
		variables: { workPrepId: params.workPrepId, jwt },
	})
	const { data: dataFiles } = useQuery(GET_BASE64_FROM_FILES, {
		variables: { workPrepId: params.workPrepId },
	})

	useEffect(() => {
		const getBlob = async () => {
			const merger = new PDFMerger()

			const currentWorkPrepTemp: WorkPrepAnswers = JSON.parse(
				data.workPrepById
			)
			setCurrentWorkPrep(currentWorkPrepTemp)

			const workPrepBlob = await pdf(
				getLocalPDF(currentWorkPrepTemp)
			).toBlob()
			await merger.add(workPrepBlob)

			if (dataFiles.getBase64FromFiles) {
				for (const file of dataFiles.getBase64FromFiles) {
					try {
						const base64 = await fetch(file)
						const blob = await base64.blob()
						await merger.add(blob)
					} catch (error) {
						setErrorOccured(true)
					}
				}
			}

			const mergedPdf = await merger.saveAsBlob()
			const url = URL.createObjectURL(mergedPdf)
			setMergedPdfUrl(`${url}`)
		}

		if (data && dataFiles) {
			getBlob()
		}
	}, [data, dataFiles])

	if ((loading && !data) || !mergedPdfUrl || !currentWorkPrep)
		return <LoadingSpinner />

	//TODO: Change/add to look at the role and if role != some type of admin - "You need permission to vied this page" etc.
	//Could maybe done in "App.tsx"?? Might be worth looking into - to prevent duplicate code
	if (role === null) return <NotLoggedIn />

	return (
		<div className="flex flex-col h-screen">
			<Button
				onClick={() => navigate(`/admin-panel/${currentWorkPrep.id}`)}
				style={{ height: '35px', borderRadius: 0 }}
				variant="contained"
			>
				{t('common.back')}
			</Button>
			{errorOccured && (
				<div className="text-center" style={{ background: '#D14547' }}>
					<Typography color="white">
						{t('workPrepPDF.errorOccured')}
					</Typography>
				</div>
			)}
			<div className="h-[calc(100%-35px)]">
				<PDFViewer
					// @ts-ignore
					src={`${mergedPdfUrl}`}
					style={{ flex: 1, width: '100%', height: '100%' }}
				></PDFViewer>
			</div>
		</div>
	)
}

export default WorkPrepPDF

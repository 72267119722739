import { Grid, Typography, Link } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import CustomDivider from '../common/CustomDivider'
import InfoText from '../common/InfoText'
import CustomCheckboxInput from '../formik/CustomCheckboxInput'
import { privacyPolicyUrls, urw_country } from '../../constants'

const SignForm = () => {
	const { t, i18n } = useTranslation()
	const currentLanguage = i18n.language

	return (
		<Grid
			container
			columnSpacing={4}
			rowSpacing={1}
			sx={{ display: 'flex', flexDirection: 'column' }}
		>
			<Grid item xs={12}>
				<InfoText
					sx={{
						borderColor: '#ef4444',
						backgroundColor: '#fef2f2',
						mb: '10px',
					}}
				>
					{t('workPrep.gdpr')}
				</InfoText>
			</Grid>
			<Grid item xs={12}>
				<InfoText
					sx={{
						borderColor: '#ef4444',
						backgroundColor: '#fef2f2',
					}}
				>
					<Trans i18nKey="workPrep.hotWorkPermissionComment" />
					<Trans
						i18nKey={
							urw_country === 'NL'
								? 'workPrep.warningText_NL'
								: 'workPrep.warningText'
						}
					/>
				</InfoText>
			</Grid>

			<Grid item>
				<InfoText
					sx={{
						borderColor: '#3b82f6',
						backgroundColor: '#eff6ff',
						mt: '10px',
					}}
				>
					<Typography fontWeight={500} sx={{ mb: '10px' }}>
						{t('workPrep.workEnviromentResponsibility.title')}
					</Typography>

					<Typography fontWeight={300}>
						1. {'    '}
						{t('workPrep.workEnviromentResponsibility.bullet1')}
					</Typography>

					<Typography fontWeight={300}>
						2.{'    '}
						{urw_country === 'NL'
							? t(
									'workPrep.workEnviromentResponsibility.bullet2_NL'
							  )
							: t(
									'workPrep.workEnviromentResponsibility.bullet2'
							  )}
					</Typography>

					<Typography fontWeight={300}>
						3.{'    '}
						{urw_country === 'NL'
							? t(
									'workPrep.workEnviromentResponsibility.bullet3_NL'
							  )
							: t(
									'workPrep.workEnviromentResponsibility.bullet3'
							  )}
					</Typography>
				</InfoText>
			</Grid>

			<Grid item>
				<CustomCheckboxInput
					label={t('workPrep.iHaveRead')}
					name="iHaveReadConfirm"
				/>
			</Grid>
			{/*
			<Grid item sx={{ display: 'flex', flexDirection: 'column' }}>
				<CustomCheckboxInput
					name="confirmPrivacyPolicy"
					label={
						<Typography fontWeight={300}>
							<Trans
								i18nKey="common.confirmPrivacyPolicy"
								components={{
									privacy: (
										<Link
											href={
												privacyPolicyUrls[
													currentLanguage
												]
											}
											target="_blank"
											rel="noopener noreferrer"
											underline="always"
										/>
									),
								}}
							/>
						</Typography>
					}
				/>
			</Grid> */}
			<CustomDivider />
		</Grid>
	)
}

export default SignForm

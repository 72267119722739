import { Button, Snackbar, Alert, Grid, Typography, Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CustomTextInput from '../components/formik/CustomTextInput'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useState } from 'react'
import HomeLayout from '../components/common/HomeLayout'
import { CHECK_VALID_ID } from '../graphql/queries/workPreps'
import { useLazyQuery } from '@apollo/client'
import ArrowForward from '@mui/icons-material/ArrowForward'
import { SnackbarData } from '../types'
import { urw_country } from '../constants'

const FireAlarmIdCheck = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [snackbarData, setSnackbarData] = useState<SnackbarData>({
		message: 'This is a success message!',
		severity: 'success',
		open: false,
	})
	const [checkValidId] = useLazyQuery(CHECK_VALID_ID)

	const closeSnackbarHandler = () => {
		const tempSnackbarData = { ...snackbarData }
		tempSnackbarData.open = false
		setSnackbarData(tempSnackbarData)
	}

	const handleSubmit = async (values: any, { resetForm }: any) => {
		setSnackbarData({
			message: t('fireAlarmShutdown.idChecking'),
			severity: 'info',
			open: true,
		})

		const relevantMallIfIdIsValid = await checkValidId({
			variables: {
				workPrepId: values.workPrepID,
			},
		})

		const mall = relevantMallIfIdIsValid.data.checkValidId
		const mallExists = [
			'Westfield Mall of Scandinavia',
			'Nacka Forum',
			'Täby Centrum',
			'Stadshart Zoetermeer',
			'Stadshart Amstelveen',
			'Mall of the Netherlands',
		].includes(mall)

		if (mallExists) {
			navigate('/fire-alarm', {
				state: { id: values.workPrepID, relevantMall: mall },
			})
		} else {
			setSnackbarData({
				message: t('fireAlarmShutdown.idSubmitFail'),
				severity: 'error',
				open: true,
			})
		}
	}

	const workPrepValidationSchema = Yup.object({
		workPrepID: Yup.string()
			.required(t('common.required'))
			.matches(
				new RegExp('[0-9]{4}-[0-9]{1,3}-[0-9]{1,3}$'),
				t('fireAlarmShutdown.idWrongFormat')
			),
	})

	const initialFormikValues = {
		workPrepID: '',
	}

	return (
		<HomeLayout>
			<Grid
				container
				width={'100%'}
				maxWidth={'500px'}
				direction="column"
				spacing={2}
			>
				<Grid item>
					<Typography component="h1" variant="h5" sx={{ mb: '10px' }}>
						{t('fireAlarmShutdown.idTitle')}
					</Typography>
				</Grid>
				<Grid item>
					<Typography
						component="h1"
						variant="body2"
						sx={{ mb: '10px' }}
					>
						{t('fireAlarmShutdown.idInfoText')}
					</Typography>
					<Link
						component={'button'}
						onClick={() =>
							window.open('/work-preparation', '_blank')
						}
						variant="body2"
						sx={{ mb: '10px' }}
						textAlign={'left'}
					>
						{t('fireAlarmShutdown.fillWorkPrep')}
					</Link>
					<Link
						component={'button'}
						onClick={() => navigate('/fire-alarm-find-id')}
						variant="body2"
						sx={{ mb: '10px' }}
						textAlign={'left'}
					>
						{t('fireAlarmShutdown.findId')}
					</Link>
					<Typography
						component="h1"
						variant="body2"
						sx={{ mb: '10px', whiteSpace: 'pre-line' }}
					>
						{urw_country === 'NL'
							? t('fireAlarmShutdown.questions_NL')
							: t('fireAlarmShutdown.questions')}
					</Typography>
				</Grid>
				<Grid item>
					<Formik
						onSubmit={handleSubmit}
						validationSchema={workPrepValidationSchema}
						initialValues={initialFormikValues}
					>
						<Form>
							<Grid container spacing={2}>
								<Grid item xs={7} sm={8}>
									<CustomTextInput
										label={t('fireAlarmShutdown.idInput')}
										name="workPrepID"
										type="text"
									/>
								</Grid>
								<Grid item xs={5} sm={4}>
									<Button
										type="submit"
										variant="contained"
										endIcon={<ArrowForward />}
										sx={{ width: '100%', height: '55px' }}
									>
										{t('workPrep.continue')}
									</Button>
								</Grid>
								<Grid item width={'100%'} height={'50px'}>
									<Snackbar
										open={snackbarData.open}
										onClose={closeSnackbarHandler}
										key={'bottom center'}
										anchorOrigin={{
											horizontal: 'center',
											vertical: 'bottom',
										}}
										sx={{
											position: 'relative',
											width: '100%',
										}}
									>
										<Alert
											onClose={closeSnackbarHandler}
											severity={snackbarData.severity}
											sx={{
												width: '100%',
											}}
										>
											{snackbarData.message}
										</Alert>
									</Snackbar>
								</Grid>
							</Grid>
						</Form>
					</Formik>
				</Grid>
			</Grid>
		</HomeLayout>
	)
}

export default FireAlarmIdCheck

import { Grid } from '@mui/material'
import { useLocation } from 'react-router-dom'
import ChangeLanguageButton from '../../components/common/ChangeLanguageButton'
import Box from '@mui/material/Box'
import frontpageImage from '../../resources/URW_Frontpage.png'
import frontPageImageNL from '../../resources/URW_Frontpage_NL.png'
import BackButton from '../../components/common/BackButton'
import { urw_country } from '../../constants'

interface Props {
	children: JSX.Element
	backToPath?: string
}

const HomeLayout = ({ children, backToPath }: Props) => {
	const location = useLocation()

	backToPath = backToPath ? backToPath : '/'

	return (
		<Box>
			<Grid container>
				<Grid item xs={12} lg={6}>
					<Grid
						container
						direction={'column'}
						height={'100vh'}
						paddingX={5}
						paddingY={5}
						wrap="nowrap"
						sx={{
							height: '100svh',
						}}
					>
						<Grid item xs={1}>
							{location.pathname != '/' && (
								<BackButton
									backToPath={backToPath}
									cancel={location.pathname == '/sign-in'}
								/>
							)}
						</Grid>
						<Grid
							item
							xs={10}
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							{children}
						</Grid>
						<Grid
							item
							xs={1}
							display="flex"
							justifyContent="start"
							alignItems="end"
						>
							<ChangeLanguageButton variant="text" />
						</Grid>
					</Grid>
				</Grid>
				<Box
					component={Grid}
					item
					xs={0}
					lg={6}
					display={{ xs: 'none', lg: 'block' }}
				>
					<Grid
						container
						direction="row"
						justifyContent="end"
						alignItems="center"
					>
						<Box
							paddingX={5}
							paddingY={5}
							sx={{ objectFit: 'cover' }}
							height={'100vh'}
							component="img"
							alt="Westfield"
							src={
								urw_country === 'NL'
									? frontPageImageNL
									: frontpageImage
							}
						/>
					</Grid>
				</Box>
			</Grid>
		</Box>
	)
}

export default HomeLayout

import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { Add } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import * as Yup from 'yup'
import {
	Alert,
	CircularProgress,
	Grid,
	MenuItem,
	Snackbar,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import CustomTextInput from '../../components/formik/CustomTextInput'
import CustomSelectInput from '../../components/formik/CustomSelectInput'
import AdminMenu from '../../components/admin/AdminMenu'
import NotLoggedIn from '../../components/common/NotLoggedIn'
import { displayRole, roleIsAuthorizedOver } from '../../utils/helperFunctions'
import { getRolesForCountry, urw_country } from '../../constants'
import { useAuth } from '../../hooks/useAuth'
import { SnackbarData } from '../../types'

const CreateUser = () => {
	const { t } = useTranslation()
	const [snackbarData, setSnackbarData] = useState<SnackbarData>({
		message: 'This is a success message!',
		severity: 'success',
		open: false,
	})
	const closeSnackbarHandler = () => {
		const tempSnackbarData = { ...snackbarData }
		tempSnackbarData.open = false
		setSnackbarData(tempSnackbarData)
	}

	const { createUser, role } = useAuth()

	if (!role) {
		return <NotLoggedIn />
	}

	const getMenuItems = () => {
		const authorizedOver = roleIsAuthorizedOver(role)

		const menuItems = authorizedOver.map((role) => (
			<MenuItem key={role} value={role}>
				{displayRole(role)}
			</MenuItem>
		))
		return menuItems
	}

	return (
		<AdminMenu>
			<Grid
				container
				width={'100%'}
				maxWidth={'600px'}
				direction="column"
				spacing={2}
			>
				<Grid item>
					<Box
						sx={{
							backgroundColor: 'primary.main',
							borderRadius: '10px',
							py: 3,
							px: 2,
						}}
					>
						<Typography
							variant="h5"
							fontWeight={'500'}
							color={'white'}
						>
							{t('createUser.title')}
						</Typography>
					</Box>
				</Grid>
				<Grid item>
					<Formik
						initialValues={{
							name: '',
							email: '',
							password: '',
							confirmPassword: '',
							role: '',
						}}
						validationSchema={Yup.object({
							name: Yup.string().required(t('common.required')),
							email: Yup.string()
								.email(t('common.invalidEmailAddress'))
								.required(t('common.required')),
							role: Yup.string()
								.oneOf(
									getRolesForCountry(urw_country),
									t('createUser.form.chooseARole')
								)
								.required(t('common.required')),
						})}
						onSubmit={async (
							values,
							{ setSubmitting, resetForm }
						) => {
							// Random password so user can choose it themselves
							const password = Math.random()
								.toString(36)
								.slice(-8)
							setSnackbarData({
								message: t('createUser.creatingUser'),
								severity: 'info',
								open: true,
							})
							const res = await createUser({
								email: values.email.toLowerCase(),
								password: password,
								name: values.name,
								role: values.role,
							})
							if (res === 'error') {
								setSnackbarData({
									message: t('createUser.error'),
									severity: 'error',
									open: true,
								})
							} else {
								setSnackbarData({
									message: t('createUser.userCreated'),
									severity: 'success',
									open: true,
								})
							}
							resetForm()
							setSubmitting(false)
						}}
					>
						{({ values, isSubmitting }) => (
							<Form>
								<Box
									sx={{
										display: 'flex',
										width: '100%',
										flexDirection: 'column',
									}}
								>
									<CustomSelectInput
										label={t('createUser.form.role')}
										name="role"
									>
										{getMenuItems()}
									</CustomSelectInput>
									<CustomTextInput
										label={t('createUser.form.name')}
										name="name"
										type="text"
									/>

									<CustomTextInput
										label={t('createUser.form.email')}
										name="email"
										type="email"
									/>

									<Alert
										severity="warning"
										sx={{
											width: '100%',
											mb: '20px',
										}}
									>
										{t('createUser.passwordInfo')}
									</Alert>
									<Button
										disabled={isSubmitting}
										type="submit"
										variant="contained"
										sx={{
											height: '50px',
											width: { xs: '100%', sm: '50%' },
										}}
										startIcon={
											isSubmitting ? (
												<CircularProgress />
											) : (
												<Add />
											)
										}
									>
										{t('createUser.form.submitBtnText')}
									</Button>
								</Box>
							</Form>
						)}
					</Formik>
				</Grid>
				<Grid item width={'100%'} height={'50px'} marginTop={'20px'}>
					<Snackbar
						open={snackbarData.open}
						onClose={closeSnackbarHandler}
						key={'bottom center'}
						anchorOrigin={{
							horizontal: 'center',
							vertical: 'bottom',
						}}
						sx={{
							position: 'relative',
							width: '100%',
						}}
					>
						<Alert
							onClose={closeSnackbarHandler}
							severity={snackbarData.severity}
							sx={{
								width: '100%',
							}}
						>
							{snackbarData.message}
						</Alert>
					</Snackbar>
				</Grid>
			</Grid>
		</AdminMenu>
	)
}

export default CreateUser

import { useState } from 'react'
import { Button, Grid, IconButton, Typography, Link } from '@mui/material'
import { useFormikContext } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete'
import CustomDivider from '../common/CustomDivider'
import CustomTextInput from '../formik/CustomTextInput'
import CustomCheckboxInput from '../formik/CustomCheckboxInput'
import { privacyPolicyUrls } from '../../constants'

const FireAlarmSign = () => {
	const { values } = useFormikContext<any>()

	const [numberOfContactPersons, setNumberOfContactPersons] =
		useState<number>(0)
	values.numberOfContactPersons = numberOfContactPersons

	const { t, i18n } = useTranslation()

	const currentLanguage = i18n.language

	return (
		<Grid container columnSpacing={4} rowSpacing={1}>
			<Grid item xs={12}>
				<Typography fontWeight={500} marginBottom={'10px'}>
					<Trans i18nKey="fireAlarmShutdown.form.creator"></Trans>
				</Typography>
			</Grid>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.createdByCompany')}
					name="createdByCompany"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.createdByPersonName')}
					name="createdByPersonName"
					type="text"
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.telephoneNumberToCreator')}
					name="telephoneNumberToCreator"
					type="text"
					inputMode="tel"
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<CustomTextInput
					label={t('workPrep.emailToCreator')}
					name="emailToCreator"
					type="text"
					inputMode="email"
				/>
			</Grid>
			{numberOfContactPersons > 0 && (
				<>
					<Grid item xs={12} display={'flex'}>
						<Typography
							fontWeight={500}
							marginBottom={'10px'}
							marginTop={'10px'}
						>
							<Trans i18nKey="fireAlarmShutdown.form.contactPersonOnSite"></Trans>
						</Typography>
						<IconButton
							onClick={() =>
								setNumberOfContactPersons(
									numberOfContactPersons - 1
								)
							}
						>
							<DeleteIcon />
						</IconButton>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t('fireAlarmShutdown.form.companyApplicant')}
							name="companyContactPerson1"
							type="text"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t('fireAlarmShutdown.form.nameApplicant')}
							name="nameContactPerson1"
							type="text"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t(
								'fireAlarmShutdown.form.phonenumberApplicant'
							)}
							name="numberContactPerson1"
							type="text"
							inputMode="tel"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t('fireAlarmShutdown.form.emailApplicant')}
							name="emailContactPerson1"
							type="text"
							inputMode="email"
						/>
					</Grid>
				</>
			)}
			{numberOfContactPersons > 1 && (
				<>
					<Grid item xs={12} display={'flex'}>
						<Typography
							fontWeight={500}
							marginBottom={'10px'}
							marginTop={'10px'}
						>
							<Trans i18nKey="fireAlarmShutdown.form.contactPersonOnSite"></Trans>
						</Typography>
						<IconButton
							onClick={() =>
								setNumberOfContactPersons(
									numberOfContactPersons - 1
								)
							}
						>
							<DeleteIcon />
						</IconButton>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t('fireAlarmShutdown.form.companyApplicant')}
							name="companyContactPerson2"
							type="text"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t('fireAlarmShutdown.form.nameApplicant')}
							name="nameContactPerson2"
							type="text"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t(
								'fireAlarmShutdown.form.phonenumberApplicant'
							)}
							name="numberContactPerson2"
							type="text"
							inputMode="tel"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CustomTextInput
							label={t('fireAlarmShutdown.form.emailApplicant')}
							name="emailContactPerson2"
							type="text"
							inputMode="email"
						/>
					</Grid>
				</>
			)}
			<Grid item xs={12} maxWidth={{ xs: '100%', sm: '400px' }}>
				<Button
					fullWidth
					sx={{ height: '50px' }}
					disabled={numberOfContactPersons == 2}
					onClick={() =>
						setNumberOfContactPersons(numberOfContactPersons + 1)
					}
					variant="contained"
				>
					{t('fireAlarmShutdown.form.addContactPerson')}
				</Button>
			</Grid>
			<CustomDivider />
			{/*
			<Grid item sx={{ display: 'flex', flexDirection: 'column' }}>
				<CustomCheckboxInput
					name="confirmPrivacyPolicy"
					label={
						<Typography fontWeight={300}>
							<Trans
								i18nKey="common.confirmPrivacyPolicy"
								components={{
									privacy: (
										<Link
											href={
												privacyPolicyUrls[
													currentLanguage
												]
											}
											target="_blank"
											rel="noopener noreferrer"
											underline="always"
										/>
									),
								}}
							/>
						</Typography>
					}
				/>
			</Grid>
			 */}
		</Grid>
	)
}

export default FireAlarmSign

import { FirebaseError } from 'firebase/app'
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signOut,
	sendPasswordResetEmail,
	User,
} from 'firebase/auth'
import React, { useState, useEffect } from 'react'
import fbApp, {
	auth,
	getDoc,
	setDoc,
	db,
	doc,
	getAnalytics,
	secondaryAppAuth,
} from '../utils/firebaseSetup'
import LoadingSpinner from '../components/common/LoadingSpinner'

import { urw_country } from '../constants'
import { getCountrySuffix } from '../constants'

interface AuthResult {
	user: User | null
	error: FirebaseError | null
}

interface LoginProps {
	email: string
	password: string
}

interface CreateUserProps {
	email: string
	password: string
	name: string
	role: string
}

interface ForgotPasswordProps {
	email: string
}

type ContextProps = {
	currentUser: User | null
	userEmail: string
	userName: string
	loadingRole: boolean
	role: string
	preferredMall: string
	jwt: string
	login: (props: LoginProps) => Promise<AuthResult>
	logout: () => Promise<void>
	createUser: (props: CreateUserProps) => Promise<'success' | 'error'>
	forgotPassword: (props: ForgotPasswordProps) => Promise<void>
	setPreferredMall: React.Dispatch<React.SetStateAction<string>>
}

export const AuthContext = React.createContext<ContextProps | undefined>(
	undefined
)

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
	const [currentUser, setCurrentUser] = useState<User | null>(null)
	const [userName, setUserName] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(true)
	const [loadingRole, setLoadingRole] = useState<boolean>(true)
	const [role, setRole] = useState<string>('')
	const [preferredMall, setPreferredMall] = useState<string>('')
	const [jwt, setJwt] = useState<string>('')

	const countrySuffix = getCountrySuffix(urw_country)

	const usersCollection =
		process.env.NODE_ENV === 'development'
			? `devUsers${countrySuffix}`
			: `users${countrySuffix}`

	// TODO: Move to backend
	const addUserToDatabase = async (
		email: string,
		name: string,
		role: string
	) => {
		await setDoc(doc(db, usersCollection, email), {
			email: email,
			name: name,
			role: role,
		})
	}

	const getUserRole = async (user: User) => {
		const email = user.email

		if (email) {
			const docRef = doc(db, usersCollection, email)
			const docSnap = await getDoc(docRef)
			if (docSnap.exists()) {
				const role = docSnap.get('role')
				setRole(role)
				//SuperAdmins can have a preferred mall
				const preferredMall = docSnap.get('preferredMall')
				if (role === 'SuperAdmin' && preferredMall) {
					setPreferredMall(preferredMall)
				}
				const name = docSnap.get('name')
				setUserName(name)
				return
			}
		}
		setRole('')
		signOut(auth)
	}

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			setCurrentUser(user)

			if (user) {
				setCurrentUser(user)
				await getUserRole(user)
				setJwt(await user.getIdToken())
			}
			setLoadingRole(false)
			setLoading(false)
		})

		return unsubscribe
	}, [])

	const createUser = async ({
		email,
		password,
		name,
		role,
	}: CreateUserProps) => {
		try {
			await createUserWithEmailAndPassword(
				secondaryAppAuth,
				email,
				password
			)
			await addUserToDatabase(email, name, role)

			//User gets to choose their own password
			await forgotPassword({ email })
		} catch {
			return 'error'
		}
		return 'success'
	}

	const login = async ({ email, password }: LoginProps) => {
		let user: User | null = null
		let error: FirebaseError | null = null

		try {
			const userCredentials = await signInWithEmailAndPassword(
				auth,
				email,
				password
			)
			user = userCredentials.user
			setCurrentUser(user)

			setLoadingRole(true)
			await getUserRole(user)
			setLoadingRole(false)
		} catch (errorMsg: any) {
			error = errorMsg
		}

		return { user, error }
	}

	const logout = async () => {
		setRole('')
		setJwt('')
		await signOut(auth)
	}

	const forgotPassword = async ({ email }: ForgotPasswordProps) => {
		try {
			await sendPasswordResetEmail(auth, email)
			console.log('Password reset link sent!')
		} catch (error) {
			console.log(error)
		}
	}

	const value = {
		currentUser,
		userEmail: currentUser && currentUser.email ? currentUser.email : '',
		userName,
		loadingRole,
		preferredMall,
		role,
		jwt,
		createUser,
		login,
		logout,
		forgotPassword,
		setCurrentUser,
		setPreferredMall,
	}

	getAnalytics(fbApp)

	return (
		<AuthContext.Provider value={value}>
			{loading ? <LoadingSpinner /> : children}
		</AuthContext.Provider>
	)
}

import { t } from 'i18next'
import {
	swedishMalls,
	dutchMalls,
	urw_country,
	getRolesForCountry,
} from '../constants'

export const zeroOrNumber = (inputNum: string) => {
	let numberToReturn: number = parseInt(inputNum)

	if (isNaN(numberToReturn)) {
		numberToReturn = 0
	}

	return numberToReturn
}

export const normalizeDate = (date: string | Date) => {
	return new Date(date).setHours(0, 0, 0, 0) // Set time to 00:00:00
}

export const dateComparator = (v1: any, v2: any) => {
	const date1 = new Date(v1)
	const date2 = new Date(v2)
	if (date1 < date2) return 1
	else if (date1 > date2) return -1
	else return 0
}

export const deepCopyObject: (obj: any) => any = (obj) => {
	return JSON.parse(JSON.stringify(obj))
}

export const getMallFromRole = (role: string | undefined) => {
	if (!role) {
		return undefined
	}

	if (role === 'SuperAdmin') {
		return 'all'
	}

	// Admin for all Swedish malls
	if (role === 'Admin - Alla centrum') {
		return 'all'
	}

	// Admin for all Dutch malls
	if (role === 'Admin - All malls') {
		return 'all-NL'
	}

	let mall = role.split(' - ')[1]

	if (mall === 'MOS') {
		mall = 'Westfield Mall of Scandinavia'
	}
	return mall
}

export const getCountryFromRole = (role: string | undefined) => {
	if (!role) {
		return undefined
	}

	if (role === 'SuperAdmin') {
		return 'all'
	}

	// Admin for all Swedish malls
	if (role === 'Admin - Alla centrum') {
		return 'SE'
	}

	// Admin for all Dutch malls
	if (role === 'Admin - All malls') {
		return 'NL'
	}

	const mall = getMallFromRole(role)

	if (!mall) {
		return undefined
	}

	if (swedishMalls.includes(mall)) {
		return 'SE'
	}

	if (dutchMalls.includes(mall)) {
		return 'NL'
	}

	return undefined
}

const isSuperAdmin = (role: string | undefined) => {
	if (!role) {
		return false
	}
	const superAccess = role === 'SuperAdmin' || role === 'Admin - Alla centrum'

	return superAccess
}

export const isAuthorized = (
	role: string | undefined,
	mall:
		| 'Mall of Scandinavia'
		| 'Nacka Forum'
		| 'Täby Centrum'
		| 'Stadshart Zoetermeer'
		| 'Stadshart Amstelveen'
		| 'Mall of the Netherlands'
		| undefined
) => {
	if (!role || !mall) {
		return false
	}

	if (isSuperAdmin(role)) {
		return true // SuperAdmin or Admin - Alla centrum
	}

	const mallRole = getMallFromRole(role)

	if (mallRole === 'all-NL') {
		return true // Admin - All malls for Netherlands
	}

	return mallRole === mall
}

export const displayRole = (role: string) => {
	const displayObj = {
		SuperAdmin: 'Superadmin',
		// Sweden
		'Admin - Alla centrum': 'Admin - Alla centrum',
		'Admin - Westfield Mall of Scandinavia':
			'Admin - Westfield Mall of Scandinavia',
		'Admin - Nacka Forum': 'Admin - Nacka Forum',
		'Admin - Täby Centrum': 'Admin - Westfield Täby Centrum',
		'Observer - MOS': 'Vakt - Westfield Mall of Scandinavia',
		'Observer - Nacka Forum': 'Vakt - Nacka Forum',
		'Observer - Täby Centrum': 'Vakt - Westfield Täby Centrum',
		'Drift - Westfield Mall of Scandinavia':
			'Drift - Westfield Mall of Scandinavia',
		'Drift - Nacka Forum': 'Drift - Nacka Forum',
		'Drift - Täby Centrum': 'Drift - Westfield Täby Centrum',
		// Netherlands
		'Admin - All malls (NL)': 'Admin - All malls',
		'Admin - Stadshart Zoetermeer': 'Admin - Stadshart Zoetermeer',
		'Admin - Stadshart Amstelveen': 'Admin - Stadshart Amstelveen',
		'Admin - Mall of the Netherlands': 'Admin - Mall of the Netherlands',
		'Observer - Stadshart Zoetermeer': 'Guard - Stadshart Zoetermeer',
		'Observer - Stadshart Amstelveen': 'Guard - Stadshart Amstelveen',
		'Observer - Mall of the Netherlands': 'Guard - Mall of the Netherlands',
		'Operations - Stadshart Zoetermeer':
			'Operations - Stadshart Zoetermeer',
		'Operations - Stadshart Amstelveen':
			'Operations - Stadshart Amstelveen',
		'Operations - Mall of the Netherlands':
			'Operations - Mall of the Netherlands',

		// General roles
		Upprättare: 'Upprättare av arbetsberedningar',
		Creator: 'Creator of work preparations',
		projectManager: t('common.projectManager'),
	}

	if (role in displayObj) return displayObj[role as keyof typeof displayObj]

	return ''
}

// Returns a list over the roles which the input role is authorized over
export const roleIsAuthorizedOver = (role: string) => {
	const authorizedOver = {
		SuperAdmin: getRolesForCountry(urw_country),
		// Sweden - All malls
		'Admin - Alla centrum': getRolesForCountry(urw_country).filter(
			(e) => e != 'SuperAdmin'
		),
		// Sweden - Mall of Scandinavia
		'Admin - Westfield Mall of Scandinavia': [
			'Admin - Westfield Mall of Scandinavia',
			'Observer - MOS',
			'Drift - Westfield Mall of Scandinavia',
			'Upprättare',
			'projectManager',
		],
		'Drift - Westfield Mall of Scandinavia': [],
		'Observer - MOS': [],
		// Sweden - Nacka Forum
		'Admin - Nacka Forum': [
			'Admin - Nacka Forum',
			'Observer - Nacka Forum',
			'Drift - Nacka Forum',
			'Upprättare',
			'projectManager',
		],
		'Drift - Nacka Forum': [],
		'Observer - Nacka Forum': [],
		// Sweden - Täby Centrum
		'Admin - Täby Centrum': [
			'Admin - Täby Centrum',
			'Observer - Täby Centrum',
			'Drift - Täby Centrum',
			'Upprättare',
			'projectManager',
		],
		'Drift - Täby Centrum': [],
		'Observer - Täby Centrum': [],

		// Netherlands - All malls
		'Admin - All malls': getRolesForCountry(urw_country).filter(
			(e) => e != 'SuperAdmin'
		),
		// Netherlands - Stadshart Zoetermeer
		'Admin - Stadshart Zoetermeer': [
			'Admin - Stadshart Zoetermeer',
			'Observer - Stadshart Zoetermeer',
			'Operations - Stadshart Zoetermeer',
			'projectManager',
		],
		'Operations - Stadshart Zoetermeer': [],
		'Observer - Stadshart Zoetermeer': [],
		// Netherlands - Stadshart Amstelveen
		'Admin - Stadshart Amstelveen': [
			'Admin - Stadshart Amstelveen',
			'Observer - Stadshart Amstelveen',
			'Operations - Stadshart Amstelveen',
			'projectManager',
		],
		'Operations - Stadshart Amstelveen': [],
		'Observer - Stadshart Amstelveen': [],
		// Netherlands - Mall of the Netherlands
		'Admin - Mall of the Netherlands': [
			'Admin - Mall of the Netherlands',
			'Observer - Mall of the Netherlands',
			'Operations - Mall of the Netherlands',
			'projectManager',
		],
		'Operations - Mall of the Netherlands': [],
		'Observer - Mall of the Netherlands': [],

		// General roles
		Upprättare: ['Upprättare'],
		Creator: ['Creator'],
		projectManager: [],
	}

	return authorizedOver[role as keyof typeof authorizedOver]
}

export const roleHasAccessTo = (role: string | undefined) => {
	if (!role) return []

	const accessObj = {
		SuperAdmin: ['workPreps', 'fireAlarms', 'serviceWorks', 'users'],
		// Sweden - All malls
		'Admin - Alla centrum': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
			'users',
		],
		'Admin - Westfield Mall of Scandinavia': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
			'users',
		],
		'Admin - Nacka Forum': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
			'users',
		],
		'Admin - Täby Centrum': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
			'users',
		],
		// Netherlands - All malls
		'Admin - All malls (NL)': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
			'users',
		],
		'Admin - Stadshart Zoetermeer': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
			'users',
		],
		'Admin - Stadshart Amstelveen': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
			'users',
		],
		'Admin - Mall of the Netherlands': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
			'users',
		],
		'Observer - MOS': ['workPreps', 'fireAlarms', 'serviceWorks'],
		'Observer - Nacka Forum': ['workPreps', 'fireAlarms', 'serviceWorks'],
		'Observer - Täby Centrum': ['workPreps', 'fireAlarms', 'serviceWorks'],
		'Observer - Stadshart Zoetermeer': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
		],
		'Observer - Stadshart Amstelveen': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
		],
		'Observer - Mall of the Netherlands': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
		],
		'Drift - Westfield Mall of Scandinavia': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
		],
		'Drift - Nacka Forum': ['workPreps', 'fireAlarms', 'serviceWorks'],
		'Drift - Täby Centrum': ['workPreps', 'fireAlarms', 'serviceWorks'],
		'Operations - Stadshart Zoetermeer': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
		],
		'Operations - Stadshart Amstelveen': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
		],
		'Operations - Mall of the Netherlands': [
			'workPreps',
			'fireAlarms',
			'serviceWorks',
		],
		// General roles
		Upprättare: ['workPreps', 'fireAlarms', 'serviceWorks', 'users'],
		Creator: ['workPreps', 'fireAlarms', 'serviceWorks', 'users'],
		projectManager: ['workPreps', 'fireAlarms'],
	}

	if (role in accessObj) return accessObj[role as keyof typeof accessObj]

	return []
}

export const extractDate = (dateString: string): string => {
	const date = new Date(dateString)
	const year = date.getFullYear()
	const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
	const day = String(date.getDate()).padStart(2, '0')
	return `${year}-${month}-${day}`
}

export const extractTime = (dateString: string): string => {
	const date = new Date(dateString)
	const hours = String(date.getHours()).padStart(2, '0')
	const minutes = String(date.getMinutes()).padStart(2, '0')
	return `${hours}:${minutes}`
}

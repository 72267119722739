import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Font,
	Image,
} from '@react-pdf/renderer'
import RobotoThinFont from '../../../../resources/fonts/Roboto-Thin.ttf'
import RobotoLightFont from '../../../../resources/fonts/Roboto-Light.ttf'
import RobotoRegularFont from '../../../../resources/fonts/Roboto-Regular.ttf'
import RobotoBoldFont from '../../../../resources/fonts/Roboto-Bold.ttf'
import { WorkPrepAnswers, WorkPrepAnswers_NL } from '../../../../types'
import { mallToImg } from '../../../../pages/admin/WorkPrepPDF'
import SignStatusListPDF_NL from '../../netherlands/SignStatusListPDF_NL'
import { useTranslation } from 'react-i18next'

Font.register({
	family: 'RobotoThin',
	src: RobotoThinFont,
})

Font.register({
	family: 'RobotoLight',
	src: RobotoLightFont,
})

Font.register({
	family: 'RobotoRegular',
	src: RobotoRegularFont,
})

Font.register({
	family: 'RobotoBold',
	src: RobotoBoldFont,
})

Font.registerHyphenationCallback((word) => [word])

// Create styles
const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		backgroundColor: 'white',
		margin: '43px',
		fontSize: 14,
		fontFamily: 'RobotoLight',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100%',
		paddingRight: '27px',
		paddingLeft: '43px',
		marginLeft: '-43px',
		marginTop: '10px',
	},
	fieldContainer: {
		display: 'flex',
		flex: '1 1 200px',
		flexDirection: 'column',
		marginRight: '15px',
	},
	fieldHeader: {
		fontFamily: 'RobotoBold',
		fontSize: 10,
	},
	fieldValue: {
		marginTop: '5px',
		fontSize: 10,
		lineHeight: 1.65,
	},
})

interface Props {
	currentWorkPrep: WorkPrepAnswers_NL | WorkPrepAnswers
}

const DocumentPDF_NL = ({ currentWorkPrep }: Props) => {
	const { t, i18n } = useTranslation()

	const lang = i18n.resolvedLanguage as 'en' | 'nl'

	const prettyDate = (dateString: Date) => {
		return new Date(dateString).toLocaleDateString(
			{ en: 'en-US', nl: 'nl-NL' }[lang]
		)
	}

	return (
		<Document title={`Arbetsberedning - ${currentWorkPrep.id}`}>
			<Page size="A4" style={styles.page}>
				<View
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'flex-end',
						width: '100%',
						paddingRight: '43px',
						paddingLeft: '43px',
						marginLeft: '-43px',
					}}
				>
					<View
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<View>
							<Text
								style={{
									fontFamily: 'RobotoLight',
									fontSize: 20,
									marginRight: '4px',
								}}
							>
								{t('workPrep.title')}
							</Text>
						</View>
						{currentWorkPrep.status ? (
							<View>
								<Text
									style={{
										fontFamily: 'RobotoBold',
										fontSize: 17,
										paddingTop: '3px',
										color: '#475569',
									}}
								>
									ID: {currentWorkPrep.id}
								</Text>
							</View>
						) : null}
					</View>

					<Image
						src={mallToImg[currentWorkPrep.relevantMall]}
						style={{ height: '30px' }}
					/>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.event')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.workSteps}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.establishedDate')}
						</Text>
						<Text style={styles.fieldValue}>
							{prettyDate(currentWorkPrep.establishedDate)}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.status')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.status || '-'}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.statusChangedBy')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.statusChangedBy || '-'}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.relevantMall')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.relevantMall}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.createdByCompany')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.creatorOfWorkPrep.company}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.createdByPersonName')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.creatorOfWorkPrep.name}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.telephoneNumberToCreator')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.creatorOfWorkPrep.telephoneNumber}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.emailToCreator')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.creatorOfWorkPrep.email}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.workArea')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.workArea}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.floor')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.floor}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.storeIdOrName')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.storeIdOrName}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.numPeople')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.numPeople}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.startDate')}
						</Text>
						<Text style={styles.fieldValue}>
							{prettyDate(currentWorkPrep.startDate)}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.endDate')}
						</Text>
						<Text style={styles.fieldValue}>
							{prettyDate(currentWorkPrep.endDate)}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.startingTime')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.workingHours.startingTime}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrep.endingTime')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.workingHours.endingTime}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View
						style={{
							...styles.fieldContainer,
							maxWidth: '116.5px',
						}}
					>
						<Text style={styles.fieldHeader}>
							{t('workPrep.hotWork')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.hotWorkPermission.answer
								? t('common.yes')
								: t('common.no')}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View
						style={{
							...styles.fieldContainer,
							maxWidth: '116.5px',
						}}
					>
						<Text style={styles.fieldHeader}>
							{t('workPrep.fireAlarmWillBeShutOff')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.fireAlarmWillBeShutOff.answer
								? t('common.yes')
								: t('common.no')}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View
						style={{
							...styles.fieldContainer,
							maxWidth: '116.5px',
						}}
					>
						<Text style={styles.fieldHeader}>
							{t('workPrepPDF.switchedOffVentilation')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.ventilationWillBeShutOff.answer
								? t('common.yes')
								: t('common.no')}
						</Text>
					</View>

					<View
						style={{
							...styles.fieldContainer,
							flex: '3',
						}}
					>
						<Text style={styles.fieldHeader}>
							{t('workPrepPDF.switchedOffVentilationTimePeriod')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.ventilationWillBeShutOff.answer
								? currentWorkPrep.ventilationWillBeShutOff
										.timePeriod
								: '-'}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View
						style={{
							...styles.fieldContainer,
							maxWidth: '116.5px',
						}}
					>
						<Text style={styles.fieldHeader}>
							{t('workPrepPDF.switchedOffSprinklerSystem')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.sprinklerSystemWillBeShutOff.answer
								? t('common.yes')
								: t('common.no')}
						</Text>
					</View>

					<View
						style={{
							...styles.fieldContainer,
							flex: '3',
						}}
					>
						<Text style={styles.fieldHeader}>
							{t(
								'workPrepPDF.switchedOffSprinklerSystemTimePeriod'
							)}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.sprinklerSystemWillBeShutOff.answer
								? currentWorkPrep.sprinklerSystemWillBeShutOff
										.timePeriod
								: '-'}
						</Text>
					</View>
				</View>

				{/* Client */}
				{currentWorkPrep.client && (
					<>
						<View style={styles.row}>
							<View style={styles.fieldContainer}>
								<Text style={styles.fieldHeader}>
									{t('workPrepPDF.clientName')}
								</Text>
								<Text style={styles.fieldValue}>
									{currentWorkPrep.client.name}
								</Text>
							</View>

							<View style={styles.fieldContainer}>
								<Text style={styles.fieldHeader}>
									{t('workPrepPDF.clientTelephone')}
								</Text>
								<Text style={styles.fieldValue}>
									{currentWorkPrep.client.telephone}
								</Text>
							</View>
						</View>

						<View style={styles.row}>
							<View style={styles.fieldContainer}>
								<Text style={styles.fieldHeader}>
									{t('workPrepPDF.clientEmail')}
								</Text>
								<Text style={styles.fieldValue}>
									{currentWorkPrep.client.email}
								</Text>
							</View>
						</View>
					</>
				)}

				<View style={styles.row} break>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrepPDF.projectManagerExist')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.projectManager?.exists
								? t('common.yes')
								: t('common.no')}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrepPDF.projectManagerName')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.projectManager?.exists
								? currentWorkPrep.projectManager.name
								: '-'}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrepPDF.projectManagerPhoneNumber')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.projectManager?.exists
								? currentWorkPrep.projectManager.telephoneNumber
								: '-'}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrepPDF.projectManagerEmailAddress')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.projectManager?.exists
								? currentWorkPrep.projectManager.email
								: '-'}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View
						style={{
							...styles.fieldContainer,
							maxWidth: '116.5px',
						}}
					>
						<Text style={styles.fieldHeader}>
							{t('workPrepPDF.insuranceExist')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.insurance.exists
								? t('common.yes')
								: t('common.no')}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrepPDF.insuranceCompany')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.insurance.exists
								? currentWorkPrep.insurance.company
								: '-'}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrepPDF.certainRisks')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.certainRisks || '-'}
						</Text>
					</View>

					<View style={styles.fieldContainer}>
						<Text style={styles.fieldHeader}>
							{t('workPrepPDF.remediesForWorkEnvironmentRisks')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.measureForWorkEnvironmentRisks ||
								'-'}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View
						style={{
							...styles.fieldContainer,
							maxWidth: '116.5px',
						}}
					>
						<Text style={styles.fieldHeader}>
							{t('workPrepPDF.additionalResources')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.additionalResources || '-'}
						</Text>
					</View>

					<View
						style={{
							...styles.fieldContainer,
							maxWidth: '116.5px',
						}}
					>
						<Text style={styles.fieldHeader}>
							{t('workPrepPDF.area')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.areaWhereWorkWillBePerformed ||
								'-'}
						</Text>
					</View>
				</View>

				<View style={styles.row}>
					<View style={{ ...styles.fieldContainer, flex: 2 }}>
						<Text style={styles.fieldHeader}>
							{t('workPrepPDF.descriptionOfWorkSteps')}
						</Text>
						<Text style={styles.fieldValue}>
							{currentWorkPrep.describeWorkSteps || '-'}
						</Text>
					</View>
				</View>
				{currentWorkPrep.creatorOfWorkPrep.signStatus ? (
					<SignStatusListPDF_NL currentWorkPrep={currentWorkPrep} />
				) : null}
			</Page>
		</Document>
	)
}

export default DocumentPDF_NL

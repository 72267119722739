import { TimePicker } from '@mui/lab'
import { SxProps, TextField } from '@mui/material'
import { useField } from 'formik'

import enLocale from 'date-fns/locale/en-US'
import svLocale from 'date-fns/locale/sv'
import nlLocale from 'date-fns/locale/nl'

// Material UI: Date picker
import DateAdapter from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useTranslation } from 'react-i18next'

interface Props {
	label: string
	name: string
	disabled?: boolean
	sx?: SxProps
	placeholder?: string
}

const localeMap = {
	en: enLocale,
	se: svLocale,
	nl: nlLocale,
}

function getLocaleString(lang: string) {
	if (lang === 'se') {
		return 'se'
	} else if (lang === 'nl') {
		return 'nl'
	} else {
		return 'en'
	}
}

const CustomTimeInput = ({ label, name, sx, disabled = false }: Props) => {
	const [field, meta, helpers] = useField({ name })

	const { setValue } = helpers

	const { i18n } = useTranslation()

	const locale = getLocaleString(i18n.resolvedLanguage.split('-')[0])

	return (
		<LocalizationProvider
			dateAdapter={DateAdapter}
			locale={localeMap[locale]}
		>
			<TimePicker
				// mask="____-__-__"
				value={field.value}
				label={label}
				onChange={(val) => setValue(val)}
				disabled={disabled}
				renderInput={(params) => (
					<TextField
						{...params}
						sx={sx}
						onBlur={field.onBlur}
						fullWidth
						id={name}
						error={meta.touched && Boolean(meta.error)}
						label={label}
						helperText={(meta.touched && meta.error) || ' '}
						disabled={disabled}
					/>
				)}
			/>
		</LocalizationProvider>
	)
}

export default CustomTimeInput
